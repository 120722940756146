import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {FormattedMessage, useIntl} from 'react-intl';

export default function Success() {
  return (
    <div className="subscription page" style={{ margin: "0px" }}>
      <Container>
        <Row className="success-page">
          <Col>
            <div className="success-body">
              <div className="success-image">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/success.png"}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="success-msg">
              <FormattedMessage id="unlock_premium.payment.success.title" defaultMessage="Congratulations and welcome onboard" />
              </div>
              <div className="success-action">
              <a target="_blank" href="https://apps.apple.com/in/app/atlentis/id1543393914">
                <button className="primary-button"><FormattedMessage id="button.downloadApp" defaultMessage="DOWNLOAD THE APP" /></button>
              </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

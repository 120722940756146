import firebase from 'firebase';

const config = {
   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID
};

firebase.initializeApp(config);
// firebase.auth().signInAnonymously().then(() => {
//    const db = firebase.firestore().enablePersistence()
// }).catch((error) => {
//       console.log(error)
//   });
export default firebase;
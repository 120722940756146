import { useState, useEffect, Fragment } from "react";
import { Container } from "react-bootstrap";
import { Select, MenuItem } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as DropdownIcon } from "../../assets/icons/dropdown-icon.svg";
import { ReactComponent as AppStoreIcon } from "../../assets/icons/app-store.svg";
import Cookies from 'universal-cookie';
import {FormattedMessage, useIntl} from 'react-intl';


const cookies = new Cookies();

const Header = ({
  homepageRoute,
}) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const cookielocale = cookies.get('language_locale') ? cookies.get('language_locale').toUpperCase() : 'EN'; 
  const [selectedLanguage, setSelectedLanguage] = useState(cookielocale);

  const history = useHistory()

  const languageData = [
    { id: 1, languageName: "en", languageCode: "EN" },
    { id: 2, languageName: "de", languageCode: "DE" },
    { id: 3, languageName: "fr", languageCode: "FR" }
  ];

  const [currentPath,setCurrentPath] = useState(history.location.pathname)
  
  const languageHandler = (e) =>{
    const languageid = e.currentTarget.attributes.getNamedItem('language_id').value
    const locale = e.currentTarget.attributes.getNamedItem('language_locale').value
    cookies.set('language_locale',locale,{path:"/",domain :process.env.REACT_APP_COOKIE_DOMAIN,maxAge :432000})
    cookies.set('language_id',languageid,{path:"/",domain :process.env.REACT_APP_COOKIE_DOMAIN,maxAge :432000})
    setSelectedLanguage(e.target.value)
    if(history.location.pathname.includes('register'))
    {
      window.location.href = "/"+locale+"/register"
    }
    else if(history.location.pathname.includes('otp-verification'))
    {
      window.location.href = "/"+locale+"/otp-verification"
    }
    else if(['/en', '/fr','/de'].includes(currentPath))
    {
      setTimeout(function(){
        window.location.href = "/"+locale
      },1000)
    }
    

    //window.location.href = "/"+locale
  }

  const MenuProps = {
    disableScrollLock: true,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  useEffect(() => {
    const header = document.querySelector("header");
    setHeaderTop(header.offsetTop);
    setHeaderHeight(header.offsetHeight);
    window.addEventListener("scroll", handleScroll);
    scroll > headerTop
      ? (document.body.style.paddingTop = `${headerHeight}px`)
      : (document.body.style.paddingTop = 0);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <Fragment>
      <header
        className={`topbar-shadow ${
          scroll > headerTop
            ? homepageRoute
              ? "is-sticky"
              : "is-sticky--alt"
            : ""
        }`}
      >
        <div
          className={`header-bottom-area ${
            scroll > headerTop
              ? ""
              : homepageRoute
              ? "transparent-style"
              : "secondary-style"
          }`}
        >
          <div className="position-relative">
            <Container className="wide">
              <div className="header-content d-flex align-items-center justify-content-between">
                <div className="header-content__logo">
                  <Link to="/">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/logo.png"}
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                  <div className="logo-message">
                    <span><FormattedMessage id = "atlentis.home_page.by.text" defaultMessage="by" /></span>
                    <FormattedMessage id = "atlentis.home_page.planitall_com.text" defaultMessage="PLAN-IT-ALL.COM" />
                  </div>
                </div>

                <div className="header-content__icons">
                 {(cookies.get('access_token') === undefined || cookies.get('access_token')==="") &&
                 
                 <>
                  <Select
                    className="country-select"
                    name="language"
                    value={selectedLanguage}
                    onChange={(e)=>languageHandler(e)}
                    displayEmpty
                    IconComponent={DropdownIcon}
                    MenuProps={MenuProps}
                  >{selectedLanguage}
                    {languageData.map((single, i) => (
                      <MenuItem key={i} value={single.languageCode} language_id={single.id} language_locale={single.languageName}>
                        {single.languageCode}
                      </MenuItem>
                    ))}
                  </Select>
                  </>
                  }
                  <a target="_blank" href="https://apps.apple.com/in/app/atlentis/id1543393914">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/images/landing/app-store-${
                        selectedLanguage === "EN"
                          ? "english"
                          : selectedLanguage === "FR"
                          ? "french"
                          : selectedLanguage === "DE" && "german"
                      }.png`
                    }
                    className="img-fluid app-store-img"
                    alt=""
                  />
                  </a>
                  <a target="_blank" href="https://apps.apple.com/in/app/atlentis/id1543393914">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/landing/app-store-mobile.png"
                    }
                    className="img-fluid app-store-img--mobile"
                    alt=""
                  />
                  </a>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default Header;

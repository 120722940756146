import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Select, MenuItem } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorValidation from "../ErrorValidation";
import Breadcrumb from "../Resuable/Breadcrumb";
import SectionTitle from "../Resuable/SectionTitle";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as DropdownIcon } from "../../assets/icons/dropdown-icon-2.svg";
import {FormattedMessage, useIntl} from 'react-intl';
import Axios from '../../Config/Axios'
import cogoToast from 'cogo-toast';
import  common  from "../../Language/common.json"
import Loader from "../Resuable/Loader";

export default function BillingAddress() {
  const history = useHistory()
  
  const intl = useIntl()
  const [loading, setLoading] = useState(true)
   
  const handleFieldChange = (e, data, type) => {
        setCountry(e.target.value);
        data.country = e.target.value;
  };

  let initialData = {
    first_name:'',
    last_name:'',
    address:'',
    contact_number:'',
    city:'',
    state:'',
    postal_code:'',
    vat_no:'',
    country:localStorage.getItem('billing_country_id'),
}

const [country, setCountry] = useState(localStorage.getItem('billing_country_id'));

  const [countryD, setCountryD] = useState({country_id:localStorage.getItem('billing_country_id'),country_name:localStorage.getItem('billing_country_name'),country_code:localStorage.getItem('billing_country_short_code')})
  const [billinginfo, setBillingInfo] = useState(initialData)

  const [errors_message,setErrorMessage] = useState({})

  const [vatCheck, setVatCheck] = useState(false) 

  const cancelClick = () => {
    history.push("/subscription-plans")
 }

 useEffect(() => {
        
  if(localStorage.getItem('plan_id')==="" || localStorage.getItem('plan_id')===undefined || localStorage.getItem('plan_id')===null){
      history.push('/subscriptions')
      return
  }
  let dataobj = {}
  Axios.post('user/billing/info',dataobj).then(response => {
      if(response.data.status)
      {
          //console.log(response.data)
          //setHdrData(response.data.data.hdr_pack)
          if(response.data.data!==null){
              
              setBillingInfo({...billinginfo,
              first_name:response.data.data.first_name ? response.data.data.first_name : '',
              last_name:response.data.data.last_name ? response.data.data.last_name : '',
              address:response.data.data.address ? response.data.data.address : '',
              city:response.data.data.city ? response.data.data.city : '',
              postal_code:response.data.data.postcode ? response.data.data.postcode : '',
              state:response.data.data.state ? response.data.data.state : '',
              vat_no:response.data.data.vat_no ? response.data.data.vat_no : '',
              contact_number:response.data.data.contact_number ? response.data.data.contact_number : ''})
              if(response.data.data.vat_no !== null && response.data.data.vat_no !== ''){
                  setVatCheck(true)
              }

          }
          setLoading(false)
          
      }else{
        setLoading(false)
          //cogoToast.error(response.data.message);
          
      }
      
  }).catch(function (error) {
      // handle error
      // console.log(error)
      setLoading(false)
     //cogoToast.error(error.message);
  })
}, [])

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(<FormattedMessage id="unlock_premium.billing.first_name.message" defaultMessage="First name is required" />),

    last_name: Yup.string()
      .required(<FormattedMessage id="unlock_premium.billing.last_name.message" defaultMessage="Last name is required" />),

    address: Yup.string().required(<FormattedMessage id="unlock_premium.billing.address.message" defaultMessage="Address is required" />),

    postal_code: Yup.string().required(<FormattedMessage id="unlock_premium.billing.postal_code.message" defaultMessage="Postal code is required" />),

    city: Yup.string().required(<FormattedMessage id="unlock_premium.billing.city.message" defaultMessage="City is required" />),

    //state: Yup.string().required("State is Required"),

    //country: Yup.string().required("Country is Required"),

    //vat_no: Yup.string().required("VAT Number is Required"),

    // contact_number: Yup.string()
    //   .required("Phone Number is Required")
    //   .test("len", "Must be exactly 10 digits ", (value) => {
    //     return /^\d{10}$/.test(value);
    //   }),
  });

  const MenuProps = {
    disableScrollLock: true,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  const checkoutCall = () => {
    let plan_id = localStorage.getItem('plan_id')
    let dataobj = {plan_id:plan_id,platform:'atlentis_web'}
    setLoading(true)
    Axios.post('/purchase/subscription/checkout',dataobj).then(response => {
        if(response.data.status)
        {
            if(response.data.data.web_url){
                localStorage.removeItem('plan_id')
                localStorage.removeItem('billing_country_id')
                localStorage.removeItem('billing_country_name')
                localStorage.removeItem('billing_country_short_code')
                localStorage.removeItem('product_id')
                window.location.replace(response.data.data.web_url)
                setTimeout(function(){
                    setLoading(false)
                },10000)
            }
            //setHdrData(response.data.data.hdr_pack)
            
            
        }else{
            cogoToast.error(response.data.message);
            setLoading(false)
            
        }
        
    }).catch(function (error) {
        // handle error
        cogoToast.error(error.message);
        setLoading(false)
    })
}

  return (
    <Fragment>
      {loading ? <Loader /> : "" }
      <div className="register page sticky-footer">
        <Container>
          <SectionTitle title={<FormattedMessage id = "atlentis.breadcrumb.subscription.billing.title" defaultMessage="Billing Address" />} />
          <Breadcrumb>
            <ul className="breadcrumb__list">
              <li>
                <Link to={`/${intl.locale}`}><FormattedMessage id = "atlentis.breadcrum.home" defaultMessage="Home" /></Link>
              </li>
              <li>
                <Link to="/subscriptions">
                  <FormattedMessage id = "atlentis.breadcrumb.subscription.title" defaultMessage="Subscriptions" />
                </Link>
              </li>
              <li>
                <Link to="/subscription-plans">
                  <FormattedMessage id = "atlentis.breadcrumb.subscription.plans" defaultMessage="Available Subscription Plans" />
                </Link>
              </li>
             
              <li><FormattedMessage id = "atlentis.breadcrumb.subscription.billing.title" defaultMessage="Billing Address" /></li>
            </ul>
          </Breadcrumb>
          <Formik
            initialValues={{
              

              first_name:'',
              last_name:'',
              address:'',
              contact_number:'',
              city:'',
              state:'',
              postal_code:'',
              vat_no:'',
              country:localStorage.getItem('billing_country_id'),
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => 
            {
              Axios.post('/user/billing/create',values).then(response => {
                if(response.data.status)
                {
                    checkoutCall()
                    //setHdrData(response.data.data.hdr_pack)
                    
                }else{
                    cogoToast.error(response.data.message);
                }
                
                }).catch(function (error) {
                    // handle error
                    cogoToast.error(error.message);
                })
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                className="register-form"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <Row className="each-field-row">
                  <Col lg={6} md={6} sm={12} className="each-field">
                    
                  <FormattedMessage id="unlock_premium.billing.first_name" defaultMessage="First Name">
                                            {placeholder => <input 
                      type="text"
                      name="first_name"
                      placeholder={placeholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.first_name}
                    />}</FormattedMessage>
                    <ErrorValidation
                      touched={touched.first_name}
                      message={errors.first_name}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="each-field">
                  <FormattedMessage id="unlock_premium.billing.last_name" defaultMessage="Last Name">
                                            {placeholder => <input 
                      type="text"
                      name="last_name"
                      placeholder={placeholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.last_name}
                    />}</FormattedMessage>
                    <ErrorValidation
                      touched={touched.last_name}
                      message={errors.last_name}
                    />
                  </Col>
                  <Col lg={12} md={12} sm={12} className="each-field">
                  <FormattedMessage id="unlock_premium.billing.address" defaultMessage="Address">
                  {placeholder => <input 
                    
                      type="text"
                      name="address"
                      placeholder={placeholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                    />}</FormattedMessage>
                    <ErrorValidation
                      touched={touched.address}
                      message={errors.address}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="each-field">
                  <FormattedMessage id="unlock_premium.billing.postal_code" defaultMessage="Postal Code">
                                            {placeholder => <input
                      type="text"
                      name="postal_code"
                      placeholder={placeholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.postal_code}
                    />}</FormattedMessage>
                    <ErrorValidation
                      touched={touched.postal_code}
                      message={errors.postal_code}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="each-field">
                  <FormattedMessage id="unlock_premium.billing.city" defaultMessage="City">
                                            {placeholder => <input 
                      type="text"
                      name="city"
                      placeholder={placeholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                    />}</FormattedMessage>
                    <ErrorValidation
                      touched={touched.city}
                      message={errors.city}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="each-field">
                  <FormattedMessage id="unlock_premium.billing.state" defaultMessage="State">
                                        {placeholder => <input
                      type="text"
                      name="state"
                      placeholder={placeholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state}
                    />}</FormattedMessage>
                    <ErrorValidation
                      touched={touched.state}
                      message={errors.state}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="each-field">
                    <Select
                      name="country"
                      value={country}
                      onBlur={handleBlur}
                      onChange={(e) => handleFieldChange(e, values, "country")}
                      displayEmpty
                      IconComponent={DropdownIcon}
                      MenuProps={MenuProps}
                      renderValue={
                        values.country !== ""
                          ? undefined
                          : () => (
                              <div className="select-placeholder">
                                {localStorage.getItem('billing_country_name')}
                              </div>
                            )
                      }
                    >
                      
                        <MenuItem  value={countryD.country_id}>
                          {countryD.country_name}
                        </MenuItem>
                      
                    </Select>
                    <ErrorValidation
                      touched={touched.country}
                      message={errors.country}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} className="each-field">
                  <div className="vat-number" style={{width:"100%",display:"flex",borderBottom: errors_message.vatNumber ? "2px solid red" : ""}}>
                  <span>{countryD.country_code}</span>
                  <FormattedMessage id="unlock_premium.billing.vat_no" defaultMessage="VAT Number">
                                            {placeholder => <input 
                      type="text"
                      name="vat_no"
                      placeholder={placeholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.vat_no}
                    />}</FormattedMessage>
                    </div>
                    <ErrorValidation
                      touched={touched.vat_no}
                      message={errors.vat_no}
                    />
                    
                  </Col>
                  <Col lg={6} md={6} sm={12} className="each-field">
                  <FormattedMessage id="unlock_premium.billing.contact_number" defaultMessage="Phone Number">
                                            {placeholder => <input 
                      type="text"
                      name="contact_number"
                      placeholder={placeholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contact_number}
                    />}</FormattedMessage>
                    <ErrorValidation
                      touched={touched.contact_number}
                      message={errors.contact_number}
                    />
                  </Col>
                </Row>
                <div className="fixed-footer">
                  <Container>
                    <div className="fixed-footer__content">
                      <button type="submit" className="primary-button">
                      <FormattedMessage id="button.proceed" defaultMessage="PROCEED" />
                      </button>
                      <button type="button" className="secondary-button" onClick={cancelClick}>
                      <FormattedMessage id="button.cancel" defaultMessage="CANCEL" />
                      </button>
                    </div>
                  </Container>
                </div>
              </form>
            )}
          </Formik>
        </Container>
      </div>
    </Fragment>
  );
}

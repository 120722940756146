import CryptoJs from "crypto-js";

let encryption_password = process.env.REACT_APP_ENCRYPTION_KEY;
let key = CryptoJs.enc.Utf8.parse(encryption_password);
let rawIV = process.env.REACT_APP_IV;
let iv = CryptoJs.enc.Utf8.parse(rawIV);

export const Encrypt = (data) => {
  if(data !== undefined && data !== "" && data !== null) {
    let cryptobject = CryptoJs.AES.encrypt(data, key, { iv: iv });
    return cryptobject.toString();
  }
   return;
}

export const Decrypt = (data) => {
  if(data !== undefined && data !== "" && data !== null) {
    let bytes = CryptoJs.AES.decrypt(data, key, { iv: iv });    
    return bytes.toString(CryptoJs.enc.Utf8);
  }
  return;
}

export const EncryptValue = (data) => {
  
  if(data !== ""){
    let cryptobject = CryptoJs.AES.encrypt(data, key, { iv: iv });
    return cryptobject.toString();
  }
  return data;
}

export const DecryptWithURI = (data) => {
    
    let datatoencrypt = decodeURIComponent(data);
    
    let bytes = CryptoJs.AES.decrypt(datatoencrypt, key, { iv: iv });
    
    let plaintext = bytes.toString(CryptoJs.enc.Utf8);
    
    if (plaintext !== "") {
      
      data = JSON.parse(plaintext);
      
    }
      return data;
  }


// New encypted code

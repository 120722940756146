import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Breadcrumb from "../Resuable/Breadcrumb";
import SectionTitle from "../Resuable/SectionTitle";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as Device } from "../../assets/icons/compare-device.svg";
import { ReactComponent as Storage } from "../../assets/icons/compare-storage.svg";
import { ReactComponent as ForwardIcon } from "../../assets/icons/forward-icon.svg";
import { ReactComponent as CheckedIcon } from "../../assets/icons/checked-icon.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/cross-icon.svg";
import { ReactComponent as Back } from "../../assets/icons/back.svg";
import Swiper from "react-id-swiper";
import Axios from "../../Config/Axios";
import cogoToast from "cogo-toast";
import {FormattedMessage, useIntl} from 'react-intl';
import Loader from "../Resuable/Loader";

export default function SubscriptionPlan(props) {

  const history = useHistory()
  const intl = useIntl()
  const params = {
    slidesPerView: 3,
    spaceBetween: 25,
    slidesPerGroup: 3,
    grabCursor: true,
    loop: true,
    loopFillGroupWithBlank: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      1200: {
        slidesPerGroup: 3,
        slidesPerView: 3,
      },
      993: {
        slidesPerGroup: 2.5,
        slidesPerView: 2.5,
      },
      768: {
        slidesPerGroup: 2,
        slidesPerView: 2,
        spaceBetween: 15,
      },
      479: {
        slidesPerGroup: 1.5,
        slidesPerView: 1.5,
        spaceBetween: 15,
      },
      320: {
        slidesPerGroup: 1,
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
    renderPrevButton: () => (
      <button className="swiper-button-prev ht-swiper-button-nav">
        <Back />
      </button>
    ),
    renderNextButton: () => (
      <button className="swiper-button-next ht-swiper-button-nav">
        <Back />
      </button>
    ),
  };

  const [loading, setLoading] = useState(true)

  const [subscriptionData,setSubscriptionData] = useState({})

  useEffect(() => {
        
    let dataobj = {}
    Axios.post('/purchase/subscription/products',dataobj).then(response => {
        if(response.data.status)
        {
          
            setSubscriptionData(response.data)
            setLoading(false)
        }else{
          cogoToast.error(response.data.message);
           setLoading(false) 
        }
    }).catch(function (error) {
        // handle error
        cogoToast.error(error.message);
        setLoading(false)
    })
}, [])

const changePackage=(id)=>{
  localStorage.setItem('product_id',id)

  history.push('/plan-details')
  return
}

  const renderPlan = (d) => {
    console.log(d);
    return (
      
      <div className={`each-rich-pack ${d.selected && "active"}`}>
        <div className="pack-header">
          <h1 className="pack-name">{d.title}</h1>
          <div className="pack-price">
            {d.price !== "0.00" && <span>{d.cost_from_label}</span>}{d.cost_min_text}
          </div>
          <div className="pack-tag-container">
            <div className={`pack-tag ${d.tag_text ? "active" : ""}`}>
              <span>{d.tag_text}</span>
            </div>
          </div>
        </div>

        <div className="pack-content">
          <div className="pack-max">
            <div className="each-max">
              <div className="pack-icon">
                <Storage />
              </div>
              <span className="pack-max-text">{d.storage_quota}</span>
            </div>
            <div className="each-max">
              <div className="pack-icon">
                <Device />
              </div>
              <span className="pack-max-text">{d.free_plan === false && d.max_device} {d.free_plan === true && d.max_device_count}</span>
            </div>
          </div>

          <div className="scrollable-area">
            <div className="points-row">
              <div className="points-msg">
              {d.available_title} {d.price === "0.00" && "Free"}
              </div>
              {d.available_description &&
                d.available_description.map((single, i) => (
                  <div className="each-point" key={i}>
                    <div className="each-point-icon">
                      <CheckedIcon />
                    </div>
                    <span>{single}</span>
                  </div>
                ))}
            </div>

            {d.not_available_description && d.not_available_description.length > 0 && (
              <div className="points-row">
                <div className="points-msg">{d.not_available_title}</div>
                {d.not_available_description.map((single, i) => (
                  <div className="each-point" key={i}>
                    <div className="each-point-icon">
                      <CrossIcon />
                    </div>
                    <span>{single}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {!d.selected ? (
          <div className="pack-footer">
            <button className="primary-button" onClick={()=>changePackage(d.id)} >
              <FormattedMessage id = "button.buy_now" defaultMessage="Buy Now" />
              </button>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <>
      {loading ? <Loader /> : "" }
    <div className="subscription page">
      <Container>
        <div className="section-title-container text-left">
          <h2 className="section-title" style={{marginBottom:"0px"}}>
            <FormattedMessage id = "atlentis.breadcrumb.subscription.plans" defaultMessage="Available Subscription Plans" />
          </h2>
        </div>
        <Breadcrumb>
          <ul className="breadcrumb__list">
          <li>
                <Link to={`/${intl.locale}`}><FormattedMessage id = "atlentis.breadcrum.home" defaultMessage="Home" /></Link>
              </li>
              <li>
                <Link to="/subscriptions"><FormattedMessage id = "atlentis.breadcrumb.subscription.title" defaultMessage="Subscriptions" /></Link>
              </li>
              <li>
                <FormattedMessage id = "atlentis.breadcrumb.subscription.plans" defaultMessage="Available Subscription Plans" />
              </li>
          </ul>
        </Breadcrumb>
        <div className="subscription-body">
          <div className="rich-features-body">
            <div className="pack-note">
              <div className="unlock-msg">
              <FormattedMessage id="unlock_premium.subscription.featured.title" defaultMessage="Unlock features with our power packed subscription plans"/>
              </div>
              <Link to={`/compare-plans`}>
                <div className="d-flex align-items-center compare-plan">
                  <div className="compare-plan-btn">
                  <FormattedMessage id = "atlentis.breadcrumb.compare.plans" defaultMessage="Compare Plans" />
                  </div>
                  <ForwardIcon />
                </div>
              </Link>
            </div>

            <div className="rich-packs">
              <Swiper {...params}>
                {subscriptionData.data && subscriptionData.data.items.map((single, i) => <div>{renderPlan(single)}</div>)}
              </Swiper>
            </div>
          </div>
        </div>
      </Container>
    </div>
    </>
  );
}

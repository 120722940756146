import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { ReactComponent as Storage } from "../../assets/icons/compare-storage.svg";
import { ReactComponent as Upload } from "../../assets/icons/compare-storage.svg";
import { ReactComponent as Device } from "../../assets/icons/compare-device.svg";
import { ReactComponent as Project } from "../../assets/icons/compare-projects.svg";
import { ReactComponent as Adjust } from "../../assets/icons/compare-adjustment.svg";
import { ReactComponent as FloorPlan } from "../../assets/icons/compare-floor-plan.svg";
import { ReactComponent as HDR1 } from "../../assets/icons/compare-hdr.svg";
import { ReactComponent as HDR2 } from "../../assets/icons/compare-hdr.svg";
import { ReactComponent as Scan } from "../../assets/icons/compare-scan.svg";
import { ReactComponent as Cleanup } from "../../assets/icons/compare-cleanup.svg";
import { ReactComponent as Correct } from "../../assets/icons/compare-correct.svg";
import { ReactComponent as Wrong } from "../../assets/icons/compare-wrong.svg";
import SectionTitle from "../Resuable/SectionTitle";
import Breadcrumb from "../Resuable/Breadcrumb";
import Axios from '../../Config/Axios'
import cogoToast from 'cogo-toast';
import {FormattedMessage, useIntl} from 'react-intl';
import  common  from "../../Language/common.json"

export default function ComparePlans() {
  

  const intl = useIntl()

  const [subscriptionData,setSubscriptionData] = useState({})
    const [compareData,setCompareData] = useState([])
    const [loading, setLoading] = useState(true)
    const [SelectedPackage,setSelectedPackage] = useState(false)
    
    useEffect(() => {
        
        let dataobj = {}
        Axios.post('/purchase/subscription/compare_plans',dataobj).then(response => {
            if(response.data.status)
            {
              console.log(response.data)
                setSubscriptionData(response.data)
                if(response.data.data.items.length > 0){
                    setSelectedPackage(response.data.data.items[0].free_plan)
                }
                let dataCompare = response.data.data.items
                let c_data = []
                let c_data_storage = []
                let c_data_devices = []
                let c_data_projects = []
                let c_data_upload = []
                let c_data_adjustments = []
                let c_data_uploadPhotos = []
                let c_data_capture = []
                let c_data_adjustmentsHDR = []
                let c_data_scans = []
                let c_data_cleanup = []
                let c_data_floorPlan = []

                let storage_t=''
                let devices_t=''
                let projects_t=''
                let upload_t=''
                let adjustments_t=''
                let uploadPhotos_t=''
                let capture_t=''
                let adjustmentsHDR_t=''
                let scans_t=''
                let cleanup_t=''
                let floorPlan_t=''

                dataCompare.forEach((element,i) => {
                    console.log(element)
                    c_data_storage.push(element.storage_quota)
                    c_data_devices.push(element.max_device)
                    c_data_projects.push(element.projects)
                    c_data_upload.push(element.property_photo_upload)
                    c_data_adjustments.push(element.photo_adjustment)
                    c_data_uploadPhotos.push(element.fp_photo_uploads)
                    c_data_capture.push(element.hdr_photo_captures)
                    c_data_adjustmentsHDR.push(element.hdr_adjustment)
                    c_data_scans.push(element.lidar_scans)
                    c_data_cleanup.push(element.lidar_scan_cleanup)
                    c_data_floorPlan.push(element.lidar_scan_to_fp)

                    storage_t=element.storage_quota_label
                    devices_t=element.max_device_label
                    projects_t=element.projects_label
                    upload_t=element.property_photo_upload_label
                    adjustments_t=element.photo_adjustment_label
                    uploadPhotos_t=element.fp_photo_uploads_label
                    capture_t=element.hdr_photo_captures_label
                    adjustmentsHDR_t=element.hdr_adjustment_label
                    scans_t=element.lidar_scans_label
                    cleanup_t=element.lidar_scan_cleanup_label
                    floorPlan_t=element.lidar_scan_to_fp_label
                    
                });
                
                c_data['storage']={values:c_data_storage,title:storage_t}
                c_data['devices']={values:c_data_devices,title:devices_t}
                c_data['projects']={values:c_data_projects,title:projects_t}
                c_data['upload']={values:c_data_upload,title:upload_t}
                c_data['adjustments']={values:c_data_adjustments,title:adjustments_t}
                c_data['uploadPhotos']={values:c_data_uploadPhotos,title:uploadPhotos_t}
                c_data['capture']={values:c_data_capture,title:capture_t}
                c_data['adjustmentsHDR']={values:c_data_adjustmentsHDR,title:adjustmentsHDR_t}
                c_data['scans']={values:c_data_scans,title:scans_t}
                c_data['cleanup']={values:c_data_cleanup,title:cleanup_t}
                c_data['floorPlan']={values:c_data_floorPlan,title:floorPlan_t}

                setCompareData(c_data)

                console.log(c_data)
            }else{
                cogoToast.error(response.data.message);
                
            }
            
        }).catch(function (error) {
            // handle error
            cogoToast.error(error.message);
        })
    }, [])

  return (
    <div className="compare-plans page">
      <Container>
        <div className="compare-plans-head">
          <div className="section-title-container text-left">
          <h2 className="section-title" style={{marginBottom:"0px"}}>
            <FormattedMessage id = "atlentis.breadcrumb.compare.plans" defaultMessage="Compare Plans" />
          </h2>
        </div>
          <Breadcrumb>
            <ul className="breadcrumb__list">
                <li>
                <Link to={`/${intl.locale}`}><FormattedMessage id = "atlentis.breadcrum.home" defaultMessage="Home" /></Link>
              </li>
              <li>
                <Link to="/subscriptions"><FormattedMessage id = "atlentis.breadcrumb.subscription.title" defaultMessage="Subscriptions" /></Link>
              </li>
              <li><FormattedMessage id = "atlentis.breadcrumb.subscription.compareplan" defaultMessage="Compare Plans" /></li>
            </ul>
          </Breadcrumb>
        </div>
        <div className="compare-plans-body">
          
                <div className="table-scroll">
                    <table className="main-table">
                        <thead>
                            <tr className="table-header">
                                <th scope="col" className="text-start" style={{verticalAlign: "middle", paddingLeft: "20px"}}><FormattedMessage id="unlock_premium.subscription.compare.general" defaultMessage="General"/></th>
                                {subscriptionData.data && subscriptionData.data.items.map(c=> (
                                    
                                        <th scope="col" style={{background: c.free_plan === true && "#F26422", color: c.free_plan === true && "white"}} key={c.id}>
                                            <div>{c.title}</div>
                                            {c.tag_text && <div className="compare-plan-tag ">{c.tag_text ? c.tag_text : ''}</div>
                                            }
                                            {c.free_plan === true && <div className="compare-plan-tag plan-active"><FormattedMessage id="unlock_premium.subscription.compare.currentplan" defaultMessage="Current Plan"/></div>
                                            }
                                        </th>
                      
                                ))}
                                
                            </tr>
                        </thead>
                        {Object.keys(compareData).length > 0  &&
                        <tbody className="table-body">
                            <tr>
                                <th className="text-start">
                                    <div>
                                        
                                        <Upload className="compare-icon" />
                                        {compareData.storage.title}
                                        
                                    </div>
                                </th>
                                {compareData.storage.values.map((d,i) => (
                                    <td key={i}>{d}</td>
                                ))}
                            </tr>
                            <tr>
                                <th className="text-start">
                                    <div>
                                        <Device className="compare-icon"  />
                                        {compareData.devices.title}
                                    </div>
                                </th>
                                {compareData.devices.values.map((d,i) => (
                                    <td key={i}>{d}</td>
                                ))}
                            </tr>
                            <tr>
                                <th className="text-start">
                                    <div>
                                        <Project className="compare-icon"  />
                                        {compareData.projects.title}
                                    </div>  
                                </th>
                                {compareData.projects.values.map((d,i) => (
                                    <td key={i}>
                                        {d === 'Unlimited' ? <Correct /> : d === 'NA' ? <Wrong /> : d}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <th className="table-text-start header-secondary"><FormattedMessage id="unlock_premium.subscription.compare.photo" defaultMessage="Photos"/></th>
                                
                            </tr>
                            <tr>
                                <th className="text-start">
                                    <div>
                                        <Device className="compare-icon"  />
                                        {compareData.upload.title}
                                    </div>
                                </th>
                                {compareData.upload.values.map((d,i) => (
                                    <td key={i}>
                                        {d === 'Unlimited' ? <Correct /> : d === 'NA' ? <Wrong /> : d}
                                    </td> 
                                ))}
                            </tr>
                            <tr>
                                <th className="text-start">
                                    <div>
                                        <Adjust className="compare-icon"  />
                                        {compareData.adjustments.title}
                                    </div>
                                </th>
                                {compareData.adjustments.values.map((d,i) => (
                                    <td key={i}>
                                        {d === 'Unlimited' ? <Correct /> : d === 'NA' ? <Wrong /> : d}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <th className="table-text-start header-secondary"><FormattedMessage id="unlock_premium.subscription.compare.floorplan" defaultMessage="Floor Plan"/></th>
                                {compareData.projects.values.map((_,i) => (
                                    <td key={i} className="empty-td" />
                                ))}
                            </tr>
                            <tr>
                                <th className="text-start">
                                    <div>
                                        <FloorPlan className="compare-icon"  />
                                        {compareData.uploadPhotos.title}
                                    </div>
                                </th>
                                {compareData.uploadPhotos.values.map((d,i) => (
                                    <td key={i}>
                                        {d === 'Unlimited' ? <Correct /> : d === 'NA' ? <Wrong /> : d}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <th className="table-text-start header-secondary"><FormattedMessage id="unlock_premium.subscription.compare.hdr" defaultMessage="HDR"/></th>
                                {compareData.projects.values.map((_,i) => (
                                    <td key={i} className="empty-td" />
                                ))}
                            </tr>
                            <tr>
                                <th className="text-start">
                                    <div>
                                        <div style={{position: "relative", display: "inline-block"}}>
                                            <HDR1 style={{position: "relative", marginRight: "10px", top: "5px", width: "45px"}} />
                                        </div>
                                        {compareData.capture.title}
                                    </div>
                                </th>
                                {compareData.capture.values.map((d,i) => (
                                    <td key={i}>
                                        {d === 'Unlimited' ? <Correct /> : d === 'NA' ? <Wrong /> : d}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <th className="text-start">
                                    <div>
                                        <Adjust className="compare-icon"  />
                                        {compareData.adjustmentsHDR.title}
                                    </div>
                                </th>
                                {compareData.adjustmentsHDR.values.map((d,i) => (
                                    <td key={i}>
                                        {d === 'Unlimited' ? <Correct /> : d === 'NA' ? <Wrong /> : d}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <th className="table-text-start header-secondary"><FormattedMessage id="unlock_premium.subscription.compare.lidarscan" defaultMessage="LiDar Scans"/></th>
                                {compareData.projects.values.map((_,i) => (
                                    <td key={i} className="empty-td" />
                                ))}
                            </tr>
                            <tr>
                                <th className="text-start">
                                    <div>
                                        <Scan className="compare-icon"  />
                                        {compareData.scans.title}
                                    </div>
                                </th>
                                {compareData.scans.values.map((d,i) => (
                                    <td key={i}>
                                        {d === 'Unlimited' ? <Correct /> : d === 'NA' ? <Wrong /> : d}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <th className="text-start">
                                    <div>
                                        <Cleanup className="compare-icon"  />
                                        {compareData.cleanup.title}
                                    </div>
                                </th>
                                {compareData.cleanup.values.map((d,i) => (
                                    <td key={i}>
                                        {d === 'Unlimited' ? <Correct /> : d === 'NA' ? <Wrong /> : d}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <th className="text-start">
                                    <div>
                                        <FloorPlan className="compare-icon" />
                                        {compareData.floorPlan.title}
                                    </div>
                                </th>
                                {compareData.floorPlan.values.map((d,i) => (
                                    <td key={i}>
                                       {d === 'Unlimited' ? <Correct /> : d === 'NA' ? <Wrong /> : d}
                                    </td>
                                ))}
                            </tr>
                        </tbody>
}
                    </table>
                </div>
            </div>
        
      </Container>
    </div>
  );
}

import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumb from "../Resuable/Breadcrumb";
import SectionTitle from "../Resuable/SectionTitle";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as Storage } from "../../assets/icons/compare-storage.svg";
import { ReactComponent as Device } from "../../assets/icons/compare-device.svg";
import { ReactComponent as CheckedIcon } from "../../assets/icons/checked-icon.svg";
import { FormattedMessage, useIntl } from "react-intl";
import Axios from "../../Config/Axios";
import cogoToast from "cogo-toast";
import common from "../../Language/common.json";
import Loader from "../Resuable/Loader";

export default function PlanDetails() {
  const history = useHistory();
  const [selectedPack, setSelectedPack] = useState("");

  const [loading, setLoading] = useState(true)

  const [selectedPackage, setSelectedPackage] = useState("");
  const [subscriptionData, setSubscriptionData] = useState({});
  const [acceptTerms, setAcceptTerms] = useState(false);
  const intl = useIntl();

  const cancelClick = () => {
    // props.history.goBack()
    history.push("/subscription-plans");
  };

  useEffect(() => {
    if (localStorage.getItem("redirect_to")) {
      localStorage.removeItem("redirect_to");
    }
    if (
      localStorage.getItem("product_id") === "" ||
      localStorage.getItem("product_id") === undefined ||
      localStorage.getItem("product_id") === null
    ) {
      history.push("/subscription-plans");
      return;
    }
    localStorage.removeItem("plan_id");
    localStorage.removeItem("billing_country_id");
    localStorage.removeItem("billing_country_name");
    localStorage.removeItem("billing_country_short_code");
    let dataobj = {
      subscription_product_id: localStorage.getItem("product_id"),
    };
    Axios.post("/purchase/subscription/product/plans", dataobj)
      .then((response) => {
        console.log(response);
        if (response.data.status) {
          if (response.data.data) {
            setSubscriptionData(response.data);
            if (response.data.data.items.length > 0) {
              setSelectedPackage(response.data.data.items[0].id);
            }
          }
          setLoading(false)
        } else {
          setLoading(false)
          cogoToast.error(response.data.message);
        }
      })
      .catch(function (error) {
        // handle error
        setLoading(false)
        cogoToast.error(error.message);
      });
  }, []);

  const purchaseHandler = () => {
    if (selectedPackage === "") {
      cogoToast.error(
        common[`${intl.locale}`]["unlock_premium.select_plan.message"]
      );
      return;
    }
    if (acceptTerms === false) {
      cogoToast.error(
        common[`${intl.locale}`]["unlock_premium.select_terms.message"]
      );
      return;
    }
    console.log(acceptTerms);
    if (subscriptionData.billing_flag === true) {
      localStorage.setItem("plan_id", selectedPackage);
      localStorage.setItem("billing_country_id", subscriptionData.country_id);
      localStorage.setItem(
        "billing_country_name",
        subscriptionData.country_name
      );
      localStorage.setItem(
        "billing_country_short_code",
        subscriptionData.country_short_code
      );

      history.push("/billing-address");

      return;
    }
    setLoading(true)

    let dataobj = { plan_id: selectedPackage, platform: "atlentis_web" };
    Axios.post("/purchase/subscription/checkout", dataobj)
      .then((response) => {
        if (response.data.status) {
          if (response.data.data.web_url) {
            setSubscriptionData({});
            localStorage.removeItem("product_id");
            localStorage.removeItem("plan_id");
            window.location.replace(response.data.data.web_url);
              setTimeout(function(){
                setLoading(false)
            },10000)
          }
        } else {
          setLoading(false)
          cogoToast.error(response.data.message);
        }
      })
      .catch(function (error) {
        // handle error
        setLoading(false)
        cogoToast.error(error.message);
      });
  };

  return (
    <Fragment>
      {loading ? <Loader /> : "" }
      <div className="subscription page sticky-footer">
        <Container>
          <SectionTitle title={subscriptionData.title} />
          <Breadcrumb>
            <ul className="breadcrumb__list">
              <li>
                <Link to={`/${intl.locale}`}>
                  <FormattedMessage
                    id="atlentis.breadcrum.home"
                    defaultMessage="Home"
                  />
                </Link>
              </li>
              <li>
                <Link to="/subscriptions">
                  <FormattedMessage
                    id="atlentis.breadcrumb.subscription.title"
                    defaultMessage="Subscriptions"
                  />
                </Link>
              </li>
              <li>
                <Link to="/subscription-plans">
                  <FormattedMessage
                    id="atlentis.breadcrumb.subscription.plans"
                    defaultMessage="Available Subscription Plans"
                  />
                </Link>
              </li>
              <li>{subscriptionData.title}</li>
            </ul>
          </Breadcrumb>
          <div className="subscription-body">
            <div className="plans__wrapper">
              <div className="plan-name">{subscriptionData.description}</div>
              <Row className="plan-max">
                <Col lg={4} md={6} sm={6} xs={12} className="single-max">
                  <Fragment>
                    <div className="icon-container">
                      <Storage />
                    </div>
                    <span>
                      <FormattedMessage
                        id="unlock_premium.subscription.storage.label"
                        defaultMessage="Max.Storage"
                      />
                      :&nbsp;{subscriptionData.storage_quota}{" "}
                      <FormattedMessage
                        id="unlock_premium.subscription.storage.unit"
                        defaultMessage="GB"
                      />
                    </span>
                  </Fragment>
                </Col>
                <Col lg={4} md={6} sm={6} xs={12} className="single-max">
                  <Fragment>
                    <div className="icon-container">
                      <Device />
                    </div>
                    <span>
                      <FormattedMessage
                        id="unlock_premium.subscription.device.label"
                        defaultMessage="Max.Devices"
                      />
                      :&nbsp;{subscriptionData.max_device}
                    </span>
                  </Fragment>
                </Col>

                <Col lg={4}></Col>
              </Row>

              <Row className="included-row">
                <Col lg={12} className="msg">
                <FormattedMessage id="unlock_premium.subscription.include.label" defaultMessage="Included in"/>
                &nbsp;<b>{subscriptionData.title}</b>
                </Col>
                <Col lg={8} className="feature-wrapper">
                  <Row>
                    {subscriptionData.available_description &&
                      subscriptionData.available_description.map(
                        (single, i) => (
                          <Col
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                            className="single-feature"
                            key={i}
                          >
                            <CheckedIcon />
                            <span>{single}</span>
                          </Col>
                        )
                      )}
                  </Row>
                </Col>
              </Row>

              <Row className="plans">
                <Col
                  xl={11}
                  lg={10}
                  md={12}
                  sm={12}
                  xs={12}
                  className="plans-col"
                >
                  {subscriptionData.data &&
                    subscriptionData.data.items.map((single, i) => (
                      <Col
                        xl={4}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        key={i}
                        className="each-pack-grid"
                      >
                        <div
                          className={`each-pack ${
                            selectedPackage === single.id ? "active" : ""
                          }`}
                          onClick={() => setSelectedPackage(single.id)}
                        >
                          <div className="content">
                            <h1 className="title">{single.title}</h1>
                            <div className="subtitle">{single.sub_title}</div>
                            {single.discount_text && (
                              <div className="subtitle">
                                {single.discount_text}
                              </div>
                            )}
                            <h2 className="price">{single.plan_cost}</h2>
                          </div>
                        </div>
                      </Col>
                    ))}
                </Col>
              </Row>

              <div className="plan-name--alt">{subscriptionData.terms}</div>
              <div className="register-terms">
                <div className="checkbox-row">
                  <div className="register-terms__checkbox">
                    <input
                      type="checkbox"
                      id="terms"
                      name="acceptTerms"
                      value={acceptTerms}
                      onChange={(e) => setAcceptTerms(e.target.checked)}
                    />
                    <label htmlFor="terms"></label>
                  </div>
                  <div className="register-terms__content">
                    {subscriptionData.terms_label1}
                    &nbsp;
                    <a
                      href={`${
                        process.env.REACT_APP_WEBSITE_URL+intl.locale
                      }/terms-conditions`}
                      target="_blank"
                    >
                      <span style={{ color: "#ff6f00" }}>
                      {subscriptionData.terms_label2}
                      </span>{" "}
                    </a>
                    {subscriptionData.terms_label3}
                    &nbsp;
                    <a
                      href={`${
                        process.env.REACT_APP_WEBSITE_URL+intl.locale
                      }/privacy-policy`}
                      target="_blank"
                    >
                      <span style={{ color: "#ff6f00" }}>
                        {subscriptionData.terms_label4}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed-footer">
            <Container>
              <div className="fixed-footer__content">
                <button className="primary-button" onClick={purchaseHandler}>
                  {subscriptionData.billing_flag === true ? (
                    <FormattedMessage
                      id="button.continue"
                      defaultMessage="CONTINUE"
                    />
                  ) : (
                    <FormattedMessage
                      id="button.subscribe"
                      defaultMessage="SUBSCRIBE"
                    />
                  )}
                </button>
                <button className="secondary-button" onClick={cancelClick}>
                  Cancel
                </button>
              </div>
            </Container>
          </div>
        </Container>
      </div>
    </Fragment>
  );
}

import React from "react";
import { ReactComponent as ModalClose } from "../../assets/icons/modal-close.svg";
import { Dialog, DialogTitle } from "@material-ui/core";

export default function Modal(props) {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={props.maxWidth}
      open={props.open}
      onClose={props.handleClick}
      className="dialogMain"
    >
      <div className={`modalContent ${props.modalClass ? props.modalClass : ""}`}>
        <DialogTitle className="modalTitle">{props.title}</DialogTitle>
        <div className="modal-close-icon" style={{cursor: "pointer"}} onClick={props.handleClick}>
          <ModalClose />
        </div>
      </div>
      {props.children}
    </Dialog>
  );
}

import React, { Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useHistory } from "react-router";
import Cookies from 'universal-cookie';

const cookies = new Cookies();


export default function Layout({
  homepageRoute,
  hideFooter,
  children,
  languageData,
  setSelectedLanguage,
  selectedLanguage,
})

{
  const languageId = {"en":1,"fr":3,"de":2}

  const history = useHistory()
  const locale = history.location.pathname.slice(1)

  if(['en','fr','de'].includes(locale))
  {
    if(cookies.get('language_locale')!==locale)
    {
      cookies.set('language_locale',locale,{path:"/",domain :process.env.REACT_APP_COOKIE_DOMAIN,maxAge :432000})
      cookies.set('language_id',languageId[locale],{path:"/",domain :process.env.REACT_APP_COOKIE_DOMAIN,maxAge :432000})
      window.location.reload()
    }    
  }
  else
  {
    const locale_array = locale.split('/')
    if(['en','fr','de'].includes(locale_array[0]))
    {
        if(cookies.get('language_locale')!==locale_array[0])
        {
          cookies.set('language_locale',locale_array[0],{path:"/",domain :process.env.REACT_APP_COOKIE_DOMAIN,maxAge :432000})
          cookies.set('language_id',languageId[locale_array[0]],{path:"/",domain :process.env.REACT_APP_COOKIE_DOMAIN,maxAge :432000})
          window.location.href = "/"+locale_array[0]+"/"+locale_array[1]
          
        }
    }
  }
  return (
    <Fragment>
      <Header
        homepageRoute={homepageRoute ? homepageRoute : false}
        languageData={languageData}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />
      {children}
      {!hideFooter && <Footer />}
    </Fragment>
  );
}

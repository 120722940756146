import React, { createRef, useState } from "react";
import { Container } from "react-bootstrap";
import Breadcrumb from "../Resuable/Breadcrumb";
import { Link } from "react-router-dom";
import SectionTitle from "../Resuable/SectionTitle";
import { Formik } from "formik";
import {FormattedMessage, useIntl} from 'react-intl';
import cogoToast from 'cogo-toast';
import common  from "../../Language/common.json"
import { Decrypt, EncryptValue } from "../../Encryption/Encryption";
import Cookies from 'universal-cookie';
import AxiosLogin from '../../Config/Axios'
import Loader from "../Resuable/Loader";
import moment from 'moment'

const cookies = new Cookies();
export default function OTPForm() {

  const intl = useIntl()

  const elemRefs = [];

  const [loading, setLoading] = useState(false)

  const autoTab = (e) => {
    const BACKSPACE_KEY = 8;
    const TAB_KEY = 9;
    const DELETE_KEY = 46;
    let tabindex = e.target.attributes.getNamedItem("data-index").value || 0;
    tabindex = Number(tabindex);
    let elem = null;
    if (e.keyCode === BACKSPACE_KEY) {
      elem = tabindex > 0 && elemRefs[tabindex - 1];
    } else if (e.keyCode !== DELETE_KEY && e.keyCode !== TAB_KEY) {
      elem = tabindex < elemRefs.length - 1 && elemRefs[tabindex + 1];
    }
    if (elem) {
      elem.current.focus();
    }
  };

  const ref1 = createRef();
  const ref2 = createRef();
  const ref3 = createRef();
  const ref4 = createRef();
  elemRefs.push(ref1, ref2, ref3, ref4);

  const resendOtp = () => {
    let email = Decrypt(localStorage.getItem('email'))
    let dataobj = {}
    dataobj['email']=email
    AxiosLogin.post('/user/resend_otp',dataobj,{headers: {'Lang': `${intl.defaultLocale}`}}).then(response => {
      if(response.data.status)
      {
        cogoToast.success(response.data.message)
        //window.location.reload()
      }else
      {  
        cogoToast.error(response.data.message)
      }
      
    }).catch(function (error) {
        // handle error
        cogoToast.error(error.message)
    })
  }

  return (
    <>
    {loading ? <Loader /> : "" }
    <div className="otp-form page">
      <Container>
        <SectionTitle title={<FormattedMessage id="atlentis.otp.page.title" defaultMessage="OTP Verification"></FormattedMessage>} />
        <Breadcrumb>
          <ul className="breadcrumb__list">
            <li>
              <Link to={`/${intl.locale}`}><FormattedMessage id="atlentis.breadcrum.home" defaultMessage="Home"></FormattedMessage></Link>
            </li>
            <li><FormattedMessage id="atlentis.breadcrum.otp" defaultMessage="OTP Verification"></FormattedMessage></li>
          </ul>
        </Breadcrumb>
        <div className="otp-form__content">
          <div className="image">
            <img
              src={
                process.env.PUBLIC_URL + "/assets/images/otp-verification.png"
              }
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="content__wrapper">
            <div className="time-rem">
              <b><FormattedMessage id="atlentis.otp.page.subtitle" defaultMessage="Enter verification code"></FormattedMessage></b>
              <span><FormattedMessage id="atlentis.otp.page.validtext" defaultMessage="valid till"></FormattedMessage> {moment().add(10, 'minutes').format('HH:mm')} </span>
            </div>
            <div className="message">
            <FormattedMessage id="atlentis.otp.page.text" defaultMessage="Please check your email and enter verification code below"></FormattedMessage>
            </div>
            <Formik
              initialValues={{
                digit1: "",
                digit2: "",
                digit3: "",
                digit4: "",
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                let error =
                  values.digit1 === "" ||
                  values.digit2 === "" ||
                  values.digit3 === "" ||
                  values.digit4 === "";
                if (error) {
                  // cogoToast.error("Please Enter OTP")
                  // console.log("error");
                  cogoToast.error(common.[`${intl.locale}`]['atlentis.otp.required.message'])
                } else {
                  let email = Decrypt(localStorage.getItem('email'))
                  let password = Decrypt(localStorage.getItem('password'))
                  let dataobj = {}
                  dataobj['email']=email
                  dataobj['password']=password
                  dataobj['otp']=values.digit1+values.digit2+values.digit3+values.digit4
                  setLoading(true)
                  AxiosLogin.post('/user/verify',dataobj,{headers: {'Lang': `${intl.defaultLocale}`}}).then(response => {
                    console.log(response);
                    if(response.data.status)
                    {
                      let data_array = response.data.data;
                      cookies.set('access_token', data_array.access_token,{domain :process.env.REACT_APP_COOKIE_DOMAIN,maxAge :data_array.expires_in})
                      cookies.set('platform_id', EncryptValue(data_array.platform_id),{domain :process.env.REACT_APP_COOKIE_DOMAIN,maxAge :data_array.expires_in})
                      cookies.set('name', data_array.first_name+' '+data_array.last_name,{domain :process.env.REACT_APP_COOKIE_DOMAIN,maxAge :data_array.expires_in})
                      cookies.set('language_locale', data_array.language,{domain :process.env.REACT_APP_COOKIE_DOMAIN,maxAge :data_array.expires_in})  
                      cookies.set('language_id', data_array.language_id,{domain :process.env.REACT_APP_COOKIE_DOMAIN,maxAge :data_array.expires_in})  
                      localStorage.removeItem('email')
                      localStorage.removeItem('password')
                      setTimeout(function(){
                        setLoading(false)
                        window.location.href='/subscriptions'
                        
                       },1000)
                      //window.location.reload()
                    }else
                    {  
                      setLoading(false)
                      cogoToast.error(response.data.message)
                    }
                    
                  }).catch(function (error) {
                      // handle error
                      setLoading(false)
                      cogoToast.error(error.message)
                  })
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <div className="verify-otp">
                    <input
                      type="text"
                      placeholder="*"
                      value={values.digit1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="digit1"
                      className="col-lg-3"
                      data-index="0"
                      ref={ref1}
                      maxLength={1}
                      onKeyUp={autoTab}
                    />
                    <input
                      type="text"
                      placeholder="*"
                      value={values.digit2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="digit2"
                      className="col-lg-3"
                      data-index="1"
                      ref={ref2}
                      maxLength={1}
                      onKeyUp={autoTab}
                    />
                    <input
                      type="text"
                      placeholder="*"
                      value={values.digit3}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="digit3"
                      className="col-lg-3"
                      data-index="2"
                      ref={ref3}
                      maxLength={1}
                      onKeyUp={autoTab}
                    />
                    <input
                      type="text"
                      placeholder="*"
                      value={values.digit4}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="digit4"
                      className="col-lg-3"
                      data-index="3"
                      ref={ref4}
                      maxLength={1}
                      onKeyUp={autoTab}
                    />
                  </div>
                  <div className="text-center">
                    <button type="submit" className="primary-button">
                    <FormattedMessage id="button.verify" defaultMessage="Verify"></FormattedMessage>
                    </button>
                  </div>
                  <div className="resend-otp">
                    <b><FormattedMessage id="atlentis.otp.resend.text1" defaultMessage="Haven't received verification code?"></FormattedMessage></b>&nbsp;
                    <span onClick={resendOtp}><FormattedMessage id="atlentis.otp.resend.text2" defaultMessage="Please resend"></FormattedMessage></span>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </div>
    </>
  );
}

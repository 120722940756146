import { Container, Row, Col } from "react-bootstrap";
const Breadcrumb = ({ children }) => {
  return (
    <div className="breadcrumb"> 
      <Container> 
        <Row>
          <Col>
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Breadcrumb;

import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumb from "../Resuable/Breadcrumb";
import SectionTitle from "../Resuable/SectionTitle";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as HDRIcon } from "../../assets/icons/hdr-icon.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus-icon.svg";
import { ReactComponent as CameraIcon } from "../../assets/icons/camera.svg";
import { ReactComponent as FloorPlanIcon } from "../../assets/icons/floor-plan-icon.svg";
import { ReactComponent as LidarIcon } from "../../assets/icons/lidar-icon.svg";
import Axios from "../../Config/Axios";
import {FormattedMessage, useIntl} from 'react-intl';
import Cookies from 'universal-cookie';
import Loader from "../Resuable/Loader";

const cookies = new Cookies();

export default function Subscription() {
  const icons = [
    
        <HDRIcon />,
        <PlusIcon />,
        <CameraIcon />,
        <FloorPlanIcon />,
        <LidarIcon />
  ];

  const intl = useIntl()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [subscriptionData,setSubscriptionData] = useState({})

  useEffect(() => {
    
    let dataobj = {}
    Axios.post('/purchase/hdr/options',dataobj).then(response => {
      
        if(response.data.status)
        {
            setSubscriptionData(response.data.data)
            setLoading(false)
        } else {
            setLoading(false)   
        }    
    }).catch(function (error) {
      console.log(error)
        // handle error    
        setLoading(false)
    })
}, [])

  const clickHandler = (type) => {
    
    if(type==='hdr'){
      history.push('/hdr-packs')
    }
    else if(type==='bluesky'){
      history.push('/blue-sky-packs')
    }
    else if(type==='subscription'){
      history.push('/subscription-plans')
    }
  }

  return (
    <>
    {loading ? <Loader /> : "" }
    <div className="subscription page">
      <Container>
        <div className="subscription-section-title">
          <div>
            <SectionTitle title={<FormattedMessage id = "unlock_premium.subscription.page.title" defaultMessage="Subscriptions" />} />
            <Breadcrumb>
              <ul className="breadcrumb__list">
              <li>
                <Link to={`/${intl.locale}`}><FormattedMessage id = "atlentis.breadcrum.home" defaultMessage="Home" /></Link>
              </li>
              <li>
                <Link to="/subscriptions"><FormattedMessage id = "atlentis.breadcrumb.subscription.title" defaultMessage="Subscriptions" /></Link>
              </li>
              </ul>
            </Breadcrumb>
          </div>
          <div className="welcome-text">
            <h1><FormattedMessage id = "unlock_premium.subscription.welcome.title" defaultMessage="Welcome" /></h1>
            <p>{cookies.get('name')}</p>
          </div>
        </div>
        <div className="subscription-body">
          <Row className="packs-container">
            
                 <Col xl={4} lg={6} md={6} sm={12} className="single-pack" onClick={e=>clickHandler('subscription')}>
                  <Fragment >
                    <div className="single-pack__image">
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/subscription/1.png"}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    
                    
                    
                    {subscriptionData.subscription_pack && subscriptionData.subscription_pack.pack_available === true && 
                    <div className="single-pack__content" >
                    <>
                    
                      <h2 className="title">{subscriptionData.subscription_pack.title}</h2>
                    
                
                        <div className="subtitle">{subscriptionData.subscription_pack.sub_title}</div>
                    
                        <div className="pack-icons">
                          {icons.map((icon, i) => (
                            <Fragment key={i}>{icon}</Fragment>
                          ))}
                        </div>
                      
                       <div className="msg">{subscriptionData.subscription_pack.plan_start}</div>
                      <h3 className="price">{subscriptionData.subscription_pack.plan_currency} {subscriptionData.subscription_pack.plan_cost}</h3> 
                      
                      </>
                      </div>
                      }
                      
                      
                  </Fragment>
                </Col> 
                 <Col xl={4} lg={6} md={6} sm={12} className="single-pack">
                 {subscriptionData.hdr_pack && subscriptionData.hdr_pack.pack_available === true &&
                  <Fragment >
                    <div className="single-pack__image" onClick={e=>clickHandler('hdr')}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/subscription/2.png"}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="single-pack__content">
                    <h2 className="title">{subscriptionData.hdr_pack.title}</h2>
                    
                    
                    <div className="msg">{subscriptionData.hdr_pack.plan_start}</div>
                    <h3 className="price">{subscriptionData.hdr_pack.plan_currency} {subscriptionData.hdr_pack.plan_cost}</h3>
                  </div>
                  </Fragment>
                }
              </Col> 
               <Col xl={4} lg={6} md={6} sm={12} className="single-pack">
               {subscriptionData.bluesky_pack && subscriptionData.bluesky_pack.pack_available === true &&
              <Fragment>
                <div className="single-pack__image" onClick={e=>clickHandler('bluesky')}>
                <img
                      src={process.env.PUBLIC_URL + "/assets/images/subscription/3.png"}
                      className="img-fluid"
                      alt=""
                    />
                </div>
                <div className="single-pack__content">
                  <h2 className="title">{subscriptionData.bluesky_pack.title}</h2>
                  
                  
                  <div className="msg">{subscriptionData.bluesky_pack.plan_start}</div>
                  <h3 className="price">{subscriptionData.bluesky_pack.plan_currency} {subscriptionData.bluesky_pack.plan_cost}</h3>
                </div>
              </Fragment>
}
            </Col> 
              
            
          </Row>
          <div className="or-text"><FormattedMessage id = "unlock_premium.subscription.or.text" defaultMessage="OR" /></div>
          <div className="text-center">
            <button className="primary-button">
            <Link to="/success"><FormattedMessage id = "button.free_plan" defaultMessage="GET STARTED WITH OUR FREE PLAN" /></Link>
            </button>
          </div>
        </div>
      </Container>
    </div>
    </>
  );
}

import React from "react";
import { Container } from "react-bootstrap";
import {FormattedMessage} from 'react-intl';

export default function Footer() {
  return (
    <footer>
      <Container className="footer__wrapper">
        <div className="footer-content">
          <span><FormattedMessage id = "atlentis.footer.text" defaultMessage="Copyrights 2023 All Rights Reserved. Powered by Catalyst Media" /></span>
        </div>
      </Container>
    </footer>
  );
}

import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumb from "../Resuable/Breadcrumb";
import SectionTitle from "../Resuable/SectionTitle";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Axios from "../../Config/Axios";
import cogoToast from "cogo-toast";
import common from "../../Language/common.json";
import Loader from "../Resuable/Loader";

export default function PlanDetails() {
  const history = useHistory();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState("");

  const [hdrData, setHdrData] = useState({});
  const [loading, setLoading] = useState(true)

  const intl = useIntl();

  useEffect(() => {
    localStorage.removeItem("pack_id");
    localStorage.removeItem("billing_country_id");
    localStorage.removeItem("billing_country_name");
    localStorage.removeItem("billing_country_short_code");
    let dataobj = {};
    Axios.post("/purchase/hdr/credit_packs", dataobj)
      .then((response) => {
        if (response.data.status) {
          setLoading(false)
          setHdrData(response.data);
          if (response.data.data) {
            if (response.data.data.items.length > 0) {
              setSelectedPackage(response.data.data.items[0].id);
            }
          }
        } else {
          setLoading(false)
          cogoToast.error(response.data.message);
        }
      })
      .catch(function (error) {
        // handle error
        setLoading(false)
        cogoToast.error(error.message);
      });
  }, []);
  const cancelClick = () => {
    // props.history.goBack()
    history.push("/");
  };
  const purchaseHandler = () => {
    if (selectedPackage === "") {
      cogoToast.error(
        common[`${intl.locale}`]["unlock_premium.select_pack.message"]
      );
      return;
    }
    if (acceptTerms === false) {
      cogoToast.error(
        common[`${intl.locale}`]["unlock_premium.select_terms.message"]
      );
      return;
    }
    if (hdrData.billing_flag === true) {
      localStorage.setItem("pack_id", selectedPackage);
      localStorage.setItem("billing_country_id", hdrData.country_id);
      localStorage.setItem("billing_country_name", hdrData.country_name);
      localStorage.setItem(
        "billing_country_short_code",
        hdrData.country_short_code
      );
      history.push("/hdrbilling-address");
      return;
    }
    setLoading(true)
    let dataobj = { pack_id: selectedPackage, platform: "atlentis_web" };
    Axios.post("/purchase/pack/checkout", dataobj)
      .then((response) => {
        if (response.data.status) {
          if (response.data.data.web_url) {
            setLoading(false)
            setHdrData({});
            localStorage.removeItem("pack_id");
            window.location.replace(response.data.data.web_url);
          }
        } else {
          setLoading(false)
          cogoToast.error(response.data.message);
        }
      })
      .catch(function (error) {
        // handle error
      });
  };



  return (
    <Fragment>
      {loading  ? <Loader /> : "" }
      <div className="subscription page sticky-footer">
        <Container>
          <SectionTitle title={hdrData.heading_title} />
          <Breadcrumb>
            <ul className="breadcrumb__list">
              <li>
                <Link to={`/${intl.locale}`}>
                  <FormattedMessage
                    id="atlentis.breadcrum.home"
                    defaultMessage="Home"
                  />
                </Link>
              </li>
              <li>
                <Link to="/subscriptions">
                  <FormattedMessage
                    id="atlentis.breadcrumb.subscription.title"
                    defaultMessage="Subscriptions"
                  />
                </Link>
              </li>

              <li>
                <FormattedMessage
                  id="atlentis.breadcrumb.hdr.title"
                  defaultMessage="HDR Packs"
                />
              </li>
            </ul>
          </Breadcrumb>
          <div className="subscription-body">
            <div className="plans__wrapper">
              <div className="select-pack-text">{hdrData.select_text}</div>
              <Row className="plans">
                <Col
                  xl={11}
                  lg={10}
                  md={12}
                  sm={12}
                  xs={12}
                  className="plans-col"
                >
                  {hdrData.data &&
                    hdrData.data.items.map((single, i) => (
                      <Col
                        xl={4}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        key={i}
                        className="each-pack-grid"
                      >
                        <div
                          className={`each-pack ${
                            selectedPackage === single.id ? "active" : ""
                          }`}
                          onClick={() => setSelectedPackage(single.id)}
                        >
                          <div className="content">
                            <h1 className="title">{single.title}</h1>
                            <div className="subtitle">{single.sub_title}</div>
                            {single.discount_lbl && (
                              <div className="subtitle">
                                {single.discount_lbl}
                              </div>
                            )}
                            <h2 className="price">{single.pack_cost}</h2>
                          </div>
                        </div>
                      </Col>
                    ))}
                </Col>
              </Row>
              <div className="register-terms">
                <div className="checkbox-row">
                  <div className="register-terms__checkbox">
                    <input
                      type="checkbox"
                      id="terms"
                      name="acceptTerms"
                      value={acceptTerms}
                      onChange={(e) => setAcceptTerms(e.target.checked)}
                    />
                    <label htmlFor="terms"></label>
                  </div>
                  <div className="register-terms__content">
                  {hdrData.terms_label1}
                    &nbsp;
                    <a
                      href={`${
                        process.env.REACT_APP_WEBSITE_URL+intl.locale
                      }/terms-conditions`}
                      target="_blank"
                    >
                      <span style={{ color: "#ff6f00" }}>
                      {hdrData.terms_label2}
                      </span>{" "}
                    </a>
                    {hdrData.terms_label3}
                    &nbsp;
                    <a
                      href={`${
                        process.env.REACT_APP_WEBSITE_URL+intl.locale
                      }/privacy-policy`}
                      target="_blank"
                    >
                      <span style={{ color: "#ff6f00" }}>
                      {hdrData.terms_label4}
                      </span>
                    </a>
                  </div>
                </div>
                <div className="agreement-msg">{hdrData.terms_text}</div>
              </div>
            </div>
          </div>
          <div className="fixed-footer">
            <Container>
              <div className="fixed-footer__content">
                <button className="primary-button" onClick={purchaseHandler}>
                  {hdrData.billing_flag === true ? (
                    <FormattedMessage
                      id="button.continue"
                      defaultMessage="CONTINUE"
                    />
                  ) : (
                    <FormattedMessage
                      id="button.purchase"
                      defaultMessage="PURCHASE"
                    />
                  )}
                </button>
                <button className="secondary-button" onClick={cancelClick}>
                  <FormattedMessage
                    id="button.cancel"
                    defaultMessage="CANCEL"
                  />
                </button>
              </div>
            </Container>
          </div>
        </Container>
      </div>
    </Fragment>
  );
}

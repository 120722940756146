import React, { useState } from "react";
import Layout from "./components/Layout/Layout";
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory  } from "react-router-dom";
import { Suspense } from "react";
import Homepage from "./components/Homepage";
import Register from "./components/Registration/Register";
import OTPForm from "./components/Registration/OTPForm";
import ComparePlans from "./components/Subscription/ComparePlans";
import Subscription from "./components/Subscription/Subscription";
import SubscriptionPlan from "./components/Subscription/SubscriptionPlan";
import PlanDetails from "./components/Subscription/PlanDetails";
import HDRPacks from "./components/Subscription/HDRPacks";
import BlueSkyPacks from "./components/Subscription/BlueSkyPacks";
import BillingAddress from "./components/Subscription/BillingAddress";
import HdrBillingAddress from "./components/Subscription/HdrBillingAddress";
import BlueskyBillingAddress from "./components/Subscription/BlueskyBillingAddress";
import Success from "./components/Subscription/Success";
import Cookies from 'universal-cookie';


const cookies = new Cookies();

const NoMatch = ({ location }) => (
  <div
    style={{
      height: "100vh",
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      width: "100vw",
      position: "absolute",
      top: "0",
      background: "white",
      padding: "0px 15px",
      textAlign: "center",
    }}
  >
    404. That’s an error. The requested URL was not found on this server.
  </div>
);

function App() 
{
  const selectedLanguageLocale = cookies.get('language_locale') ? cookies.get('language_locale').toUpperCase() : 'EN'
  const [selectedLanguage, setSelectedLanguage] = useState(selectedLanguageLocale);
  const [urlLanguage, setUrlLanguage] = useState(selectedLanguage.toLowerCase());

  const languageData = [
    { id: 1, languageName: "English", languageCode: "EN" },
    { id: 2, languageName: "German", languageCode: "DE" },
    { id: 3, languageName: "French", languageCode: "FR" }
  ];


  return (
    <div className="app">
      <Router>
        <Suspense fallback="loading">
          <Switch>
          <Route exact path="/subscriptions">
              <Layout
                languageData={languageData}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
              >
                <Subscription />
              </Layout>
            </Route>
            <Route exact path="/subscription-plans">
              <Layout
                languageData={languageData}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
              >
                <SubscriptionPlan />
              </Layout>
            </Route>
            <Route exact path="/compare-plans">
              <Layout
                languageData={languageData}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
              >
                <ComparePlans />
              </Layout>
            </Route>
            <Route exact path="/plan-details">
              <Layout
                languageData={languageData}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                hideFooter={true}
              >
                <PlanDetails />
              </Layout>
            </Route>
            <Route exact path="/hdr-packs">
              <Layout
                languageData={languageData}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                hideFooter={true}
              >
                <HDRPacks />
              </Layout>
            </Route>
            <Route exact path="/blue-sky-packs">
              <Layout
                languageData={languageData}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                hideFooter={true}
              >
                <BlueSkyPacks />
              </Layout>
            </Route>
            <Route exact path="/billing-address">
              <Layout
                languageData={languageData}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                hideFooter={true}
              >
                <BillingAddress />
              </Layout>
            </Route>
            <Route exact path="/hdrbilling-address">
              <Layout
                languageData={languageData}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                hideFooter={true}
              >
                <HdrBillingAddress />
              </Layout>
            </Route>
            <Route exact path="/blueskybilling-address">
              <Layout
                languageData={languageData}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                hideFooter={true}
              >
                <BlueskyBillingAddress />
              </Layout>
            </Route>
            <Route exact path="/success">
              <Layout
                languageData={languageData}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                hideFooter={true}
              >
                <Success />
              </Layout>
            </Route>
            <Route exact path={`/:${urlLanguage}`} component={Homepage}>
              <Layout
                homepageRoute={true}
                languageData={languageData}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
              
              >
                <Homepage selectedLanguage={selectedLanguage}  />
              </Layout>
            </Route>
            <Route exact path={`/:${urlLanguage}/register`}>
              <Layout
                languageData={languageData}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
              >
                <Register />
              </Layout>
            </Route>
            <Route exact path={`/:${urlLanguage}/otp-verification`}>
              <Layout
                languageData={languageData}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
              >
                <OTPForm />
              </Layout>
            </Route>
            
            <Redirect to={`/${urlLanguage}`} />
            <Route component={NoMatch} />
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;

import React, { Fragment } from "react";
import { Container } from "react-bootstrap";
import { ReactComponent as HDRIcon } from "../../assets/icons/hdr-icon.svg";
import { ReactComponent as LidarIcon } from "../../assets/icons/lidar-icon.svg";
import { ReactComponent as MagicIcon } from "../../assets/icons/magic-icon.svg";
import { ReactComponent as FloorPlanIcon } from "../../assets/icons/floor-plan-icon.svg";
import { ReactComponent as EasyAccessIcon } from "../../assets/icons/easy-access-icon.svg";
import { ReactComponent as MobileIcon } from "../../assets/icons/mobile-icon.svg";
import { FormattedMessage, useIntl } from "react-intl";

export default function Banner({ selectedLanguage }) {
  const data = [
    {
      id: 1,
      description: (
        <FormattedMessage
          id="atlentis.home_page.lidar.text"
          defaultMessage="3D scan of propery to create floor plan"
        />
      ),
      svg: <LidarIcon />,
    },
    {
      id: 2,
      description: (
        <FormattedMessage
          id="atlentis.home_page.bluesky.text"
          defaultMessage="Photo Blue Sky Replacement"
        />
      ),
      svg: <MagicIcon />,
    },
    {
      id: 3,
      description: (
        <FormattedMessage
          id="atlentis.home_page.hdr.text"
          defaultMessage="Stunning HDR photos"
        />
      ),
      svg: <HDRIcon />,
    },
   
    {
      id: 4,
      description: (
        <FormattedMessage
          id="atlentis.home_page.planitall.text"
          defaultMessage="Easy Access to PLAN-IT-ALL Services"
        />
      ),
      svg: <EasyAccessIcon />,
    },
    {
      id: 5,
      description: (
        <FormattedMessage
          id="atlentis.home_page.other.text"
          defaultMessage="Instantly sync all projects, pictures, scans & floorplans across all your devices"
        />
      ),
      svg: <MobileIcon />,
    },
    // {
    //   id: 6,
    //   description: (
    //     <FormattedMessage
    //       id="atlentis.home_page.floorplan.text"
    //       defaultMessage="Create Floor Plans from your 3D LIDAR scans or from uploaded pictures/sketches"
    //     />
    //   ),
    //   svg: <FloorPlanIcon />,
    // },
  ];

  return (
    <div className="banner">
      <div className="wide-banner">
        <img
          src={process.env.PUBLIC_URL + "/assets/images/landing/banner3.jpeg"}
          className="img-fluid banner-image"
          alt=""
        />
      </div>

      <div className="iphone-container">
        <img
          src={
            process.env.PUBLIC_URL +
            `/assets/images/landing/iphone-${
              selectedLanguage === "EN"
                ? "english"
                : selectedLanguage === "FR"
                ? "french"
                : selectedLanguage === "DE" && "german"
            }.png`
          }
          className="img-fluid"
          alt=""
        />
      </div>

      <div className="banner-body">
        <Container>
          <div className="banner-body__wrapper">
            <div className="heading">
              <FormattedMessage
                id="atlentis.home_page.title"
                defaultMessage="Unlocks the potential of your iPhone* and iPad*"
              />
            </div>
            <div className="points-container">
              {data &&
                data.length > 0 &&
                data.map((single, i) => (
                  <Fragment key={i}>
                    <div className="single-point">
                      <div className="icon">{single.svg}</div>
                      <div className="description">{single.description}</div>
                    </div>
                  </Fragment>
                ))}
            </div>
            <div className="note">
              <div className="single-note">
                *&nbsp;{" "}
                <FormattedMessage
                  id="atlentis.home_page.note.text1"
                  defaultMessage="ATLENTIS requires devices equipped with min. iOS 13."
                />
              </div>
              <div className="single-note space-pl--10">
                <FormattedMessage
                  id="atlentis.home_page.note.text2"
                  defaultMessage="LIDAR scanning features only available with devices equipped
                with a LIDAR sensor."
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

import React from 'react'
import {FormattedMessage} from 'react-intl';

export default function Loader() {
    return (
        <div className="loader-container">
            <div className="loader"></div>
            <div className="loader-msg"><FormattedMessage id="loader" defaultMessage="Loading.."/></div>
        </div>
    )
}
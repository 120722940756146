const SectionTitle = ({ title, subtitle }) => {
    return (
      <div className="section-title-container text-left">
        <h2 className="section-title" style={{marginBottom: subtitle ? "6px" : "0px"}}>
          {title}
        </h2>
        {subtitle ? <h4 className="section-subtitle">{subtitle}</h4> : ""}
      </div>
    );
};
  
export default SectionTitle;
  
import React, { Fragment } from "react";
import Banner from "./Banner";
import Promotion from "./Promotion";
import Features from "./Features";

export default function Homepage({ selectedLanguage }) {
  return (
    <Fragment>
      <Banner selectedLanguage={selectedLanguage} />
      <Promotion selectedLanguage={selectedLanguage} />
      <Features />
    </Fragment>
  );
}

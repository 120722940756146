import React, { createRef } from "react";
import { DialogContent } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorValidation from "../ErrorValidation";
import {FormattedMessage, useIntl} from 'react-intl';
import { Decrypt, EncryptValue } from "../../Encryption/Encryption";
import Cookies from 'universal-cookie';
import AxiosLogin from '../../Config/Axios'
import cogoToast from 'cogo-toast';
import common  from "../../Language/common.json"

export default function ResetPassword({setResetPasswordModal}) {
  const validationSchema = Yup.object().shape({
    password: Yup.string().required(<FormattedMessage
      id = "atlentis.reset.password.message" defaultMessage="Password is Required" />),

    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], <FormattedMessage
      id = "atlentis.reset.password.notmatched" defaultMessage="Password don't match" />)
      .required(<FormattedMessage
        id = "atlentis.reset.new_password.message" defaultMessage="New Confirm Password is Required" />)
  });

  const intl = useIntl()
  const elemRefs = [];

  const autoTab = (e) => {
    const BACKSPACE_KEY = 8;
    const TAB_KEY = 9;
    const DELETE_KEY = 46;
    let tabindex = e.target.attributes.getNamedItem("data-index").value || 0;
    tabindex = Number(tabindex);
    let elem = null;
    if (e.keyCode === BACKSPACE_KEY) {
      elem = tabindex > 0 && elemRefs[tabindex - 1];
    } else if (e.keyCode !== DELETE_KEY && e.keyCode !== TAB_KEY) {
      elem = tabindex < elemRefs.length - 1 && elemRefs[tabindex + 1];
    }
    if (elem) {
      elem.current.focus();
    }
  };

  const ref1 = createRef();
  const ref2 = createRef();
  const ref3 = createRef();
  const ref4 = createRef();
  elemRefs.push(ref1, ref2, ref3, ref4);

  return (
    <DialogContent className="dialogContent register">
      <Formik
        initialValues={{
          password: "",
          password_confirmation: "",
          otp: {
            digit1: "",
            digit2: "",
            digit3: "",
            digit4: "",
          },
        }}
        validationSchema={validationSchema}
        
        onSubmit={(values, { setSubmitting, resetForm }) => {
          let error =
            values.otp.digit1 === "" ||
            values.otp.digit2 === "" ||
            values.otp.digit3 === "" ||
            values.otp.digit4 === "";
          if (error) {
            // cogoToast.error("Please Enter OTP")
            // console.log("error");
            cogoToast.error(common.[`${intl.locale}`]['atlentis.otp.required.message'])
            return
          }

        let patt = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
        let resultB = patt.test(values.password);
        if(resultB===false){
          cogoToast.error(common.[`${intl.locale}`]['atlentis.register.password.pattern'],5000)
          return 
        }
        //   setResetPasswordModal(false)
        let email = Decrypt(localStorage.getItem('email'))
        let dataobj = {}
        dataobj['email']=email
        dataobj['password']=values.password
        dataobj['password_confirmation']=values.password_confirmation
        dataobj['otp']=values.otp.digit1+values.otp.digit2+values.otp.digit3+values.otp.digit4
          AxiosLogin.post('/user/reset-password',dataobj,{headers: {'Lang': `${intl.defaultLocale}`}}).then(response => {
                  
            if(response.data.status)
            {
              localStorage.removeItem('email')
              setResetPasswordModal(false)
              cogoToast.success(response.data.message)
              //window.location.reload()
            }else
            {  
              cogoToast.error(response.data.message)
            }
            
          }).catch(function (error) {
            // handle error
            cogoToast.error(error.message)
          })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <div className="register-form login-modal">
            <form autoComplete="off" onSubmit={handleSubmit}>
              <Row>
                <Col lg={12} className="forgot-password-msg">
                  <div className="message">
                  <FormattedMessage id="atlentis.reset.text" defaultMessage="Please enter your password and otp to reset your password."></FormattedMessage>
                  </div>
                </Col>
                <Col lg={12} className="each-field">
                <FormattedMessage id="atlentis.reset.form.password" defaultMessage="Enter New Password">
                  {placeholder =>
                  <input
                    type="password"
                    placeholder={placeholder}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />}</FormattedMessage>
                  <ErrorValidation
                    touched={touched.password}
                    message={errors.password}
                  />
                </Col>
                <Col lg={12} className="each-field">
                <FormattedMessage id="atlentis.reset.form.confirm_password" defaultMessage="Enter New Confirm Password">
                  {placeholder =>
                  <input
                    type="password"
                    placeholder={placeholder}
                    name="password_confirmation"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password_confirmation}
                  />}</FormattedMessage>
                  <ErrorValidation
                    touched={touched.password_confirmation}
                    message={errors.password_confirmation}
                  />
                </Col>
                <Col lg={12} className="otp-column">
                  <div className="otp-label"><FormattedMessage id="atlentis.reset.page.otp" defaultMessage="Enter OTP below"></FormattedMessage></div>
                  <div className="verify-otp">
                    <input
                      type="text"
                      placeholder="*"
                      value={values.otp.digit1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="otp.digit1"
                      className="col-lg-3"
                      data-index="0"
                      ref={ref1}
                      maxLength={1}
                      onKeyUp={autoTab}
                    />
                    <input
                      type="text"
                      placeholder="*"
                      value={values.otp.digit2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="otp.digit2"
                      className="col-lg-3"
                      data-index="1"
                      ref={ref2}
                      maxLength={1}
                      onKeyUp={autoTab}
                    />
                    <input
                      type="text"
                      placeholder="*"
                      value={values.otp.digit3}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="otp.digit3"
                      className="col-lg-3"
                      data-index="2"
                      ref={ref3}
                      maxLength={1}
                      onKeyUp={autoTab}
                    />
                    <input
                      type="text"
                      placeholder="*"
                      value={values.otp.digit4}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="otp.digit4"
                      className="col-lg-3"
                      data-index="3"
                      ref={ref4}
                      maxLength={1}
                      onKeyUp={autoTab}
                    />
                  </div>
                  <ErrorValidation touched={touched.otp} message={errors.otp} />
                </Col>
                <Col lg={12} className="login-modal-footer style2">
                  <div className="login-actions">
                    <button type="submit" className="primary-button">
                    <FormattedMessage id="button.reset_password" defaultMessage="Reset Password"></FormattedMessage>
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        )}
      </Formik>
    </DialogContent>
  );
}

import Axios from 'axios'
import Cookies from 'universal-cookie';
import CryptoJs from "crypto-js";
import {DecryptWithURI} from "../Encryption/Encryption"

const cookies = new Cookies();

// axios.defaults.baseURL = 'https://app.storiyoh.com/api/new_version/v2/web/'; //Live
Axios.defaults.baseURL = process.env.REACT_APP_API_URL; // local

Axios.defaults.headers.common['api-key'] = process.env.REACT_APP_API_KEY;
Axios.defaults.headers.common['Authorization'] = 'Bearer '+cookies.get('access_token');

// New encypted code
let encryption_password = process.env.REACT_APP_ENCRYPTION_KEY;
let key = CryptoJs.enc.Utf8.parse(encryption_password);
let rawIV = process.env.REACT_APP_IV;

Axios.interceptors.request.use(request => {
  if(request.url !== '/firestore/addproject' && request.url !== '/firestore/editproject' && request.url !== '/firestore/addphotos' && request.url !== '/firestore/addfloorplans') {
    if(Object.keys(request.data).length !==0){
      let iv = CryptoJs.enc.Utf8.parse(rawIV);
      let datatoencrypt = JSON.stringify(request.data);
      let cryptobject = CryptoJs.AES.encrypt(datatoencrypt, key, { iv: iv });
      request.data = { encData: encodeURIComponent(cryptobject.toString()) };
    }
  }
  return request
},
error => {
  Promise.reject(error)
})

Axios.interceptors.response.use(response => {
  let iv = CryptoJs.enc.Utf8.parse(rawIV);
  let datatoencrypt = decodeURIComponent(response.data);
  
  let bytes = CryptoJs.AES.decrypt(datatoencrypt, key, { iv: iv });
  
  let plaintext = bytes.toString(CryptoJs.enc.Utf8);
  
  if (plaintext !== "") {
    response.data = JSON.parse(plaintext);
  }
  return response
},
function (error) {
  if((error.toJSON().message !== 'Network Error' && error.response.status === 422) || (error.toJSON().message !== 'Network Error' && error.response.status === 401))
  {
    if(error.response.status === 401) {
      // console.log(error.response)
      window.location.replace('/')
      return 
  }
    return Promise.reject(DecryptWithURI(error.response.data));
  }
  
  return Promise.reject(error);
})
// New encypted code

export default Axios
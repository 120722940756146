import React from "react";
import { DialogContent } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorValidation from "../ErrorValidation";
import {FormattedMessage, useIntl} from 'react-intl';
import AxiosLogin from '../../Config/Axios'
import { EncryptValue } from '../../Encryption/Encryption'
import cogoToast from 'cogo-toast';

export default function ForgotPassword({
  setForgotPasswordModal,
  setResetPasswordModal,
}) {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(<FormattedMessage
      id = "atlentis.register.email.message" defaultMessage="Email is required" />).email(<FormattedMessage
        id = "atlentis.register.email.invalid" defaultMessage="Invalid Email ID" />),
  });
  const intl = useIntl()

  return (
    <DialogContent className="dialogContent register">
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          AxiosLogin.post('/user/forgot-password',values,{headers: {'Lang': `${intl.defaultLocale}`}}).then(response => {
                
            if(response.data.status)
            {
              localStorage.setItem('email',EncryptValue(values.email))
              setForgotPasswordModal(false)
              setResetPasswordModal(true)
              //window.location.reload()
            }else
            {  
              cogoToast.error(response.data.message)
            }
            
          }).catch(function (error) {
            // handle error
            cogoToast.error(error.message)
          })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <div className="register-form login-modal">
            <form autoComplete="off" onSubmit={handleSubmit}>
              <Row>
                <Col lg={12} className="forgot-password-msg">
                  <div className="message">
                  <FormattedMessage id="atlentis.forgotpassword.text" defaultMessage="Please enter your email address. You will receive an otp to
                    create a new password."></FormattedMessage>
                  </div>
                </Col>
                <Col lg={12} className="each-field each-field--alt">
                <FormattedMessage id="atlentis.register.form.email" defaultMessage="Email Address">
                  {placeholder =>
                  <input
                    type="email"
                    placeholder={placeholder}
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />}</FormattedMessage>
                  <ErrorValidation
                    touched={touched.email}
                    message={errors.email}
                  />
                </Col>
                <Col lg={12} className="login-modal-footer style2">
                  <div className="login-actions">
                    <button type="submit" className="primary-button">
                    <FormattedMessage id="button.send" defaultMessage="Send"></FormattedMessage>
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        )}
      </Formik>
    </DialogContent>
  );
}

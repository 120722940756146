import React, { useState }  from "react";
import { DialogContent } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorValidation from "../ErrorValidation";
import {FormattedMessage, useIntl} from 'react-intl';
import Cookies from 'universal-cookie';
import AxiosLogin from '../../Config/Axios'
import { EncryptValue } from '../../Encryption/Encryption'
import cogoToast from 'cogo-toast';
import { Link, useHistory } from "react-router-dom";
import firestore from "../../Firebase/Config"
import Loader from "../Resuable/Loader";


const cookies = new Cookies();

export default function LoginModal({ setForgotPasswordModal, setLoginModal }) {

  const intl = useIntl()
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(<FormattedMessage
      id = "atlentis.register.email.message" defaultMessage="Email is required" />).email(<FormattedMessage
        id = "atlentis.register.email.invalid" defaultMessage="Invalid Email ID" />),
    password: Yup.string().required(<FormattedMessage
      id = "atlentis.register.password.message" defaultMessage="Password is Required" />),
  });

  return (
    <>
    {loading ? <Loader /> : "" }
    <DialogContent className="dialogContent register">
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
         
          setLoading(true)
          AxiosLogin.post('/user/login',values,{headers: {'Lang': `${intl.defaultLocale}`}}).then(response => {
            
            if(response.data.status)
            {

              let data_array = response.data.data;
              cookies.set('access_token', data_array.access_token,{domain :process.env.REACT_APP_COOKIE_DOMAIN,maxAge :data_array.expires_in})
              cookies.set('platform_id', EncryptValue(data_array.platform_id),{domain :process.env.REACT_APP_COOKIE_DOMAIN,maxAge :data_array.expires_in})
              cookies.set('name', data_array.first_name+' '+data_array.last_name,{domain :process.env.REACT_APP_COOKIE_DOMAIN,maxAge :data_array.expires_in})
              cookies.set('language_locale', data_array.language_locale,{domain :process.env.REACT_APP_COOKIE_DOMAIN,maxAge :data_array.expires_in})  
              cookies.set('language_id', data_array.language_id,{domain :process.env.REACT_APP_COOKIE_DOMAIN,maxAge :data_array.expires_in})  
              //window.location.reload()
              
                firestore.auth().signInAnonymously().then(() => {
                    const db = firestore.firestore()
                    db.collection("users").doc(data_array.platform_id).onSnapshot((querySnapshot) => {
                      
                    //db.collection("projects").orderBy('created_at', 'desc').get().then((querySnapshot) => {
                        if(querySnapshot.data() !== undefined) 
                        {
                            if(querySnapshot.data().subscription.premium === true || querySnapshot.data().hdr_pack.active === true || querySnapshot.data().bluesky_pack.active===true)
                            {
                              console.log(querySnapshot.data())
                              window.location.href=process.env.REACT_APP_LIBRARY_URL
                              setLoading(false)  
                            }
                            else
                            {
                              setLoginModal(false)
                              setTimeout(function(){
                                setLoading(false)  
                                window.location.href='/subscriptions'
                              },1000)
                            }
                        }
                        
                        
                    });  
                             
                })
                .catch((errorR) => {
                  setLoading(false) 
                    console.log(errorR)
                });

                 
            
              
              
            }else{
                    
              cogoToast.error(response.data.message)
            }
            
          }).catch(function (error) {
            // handle error
            cogoToast.error(error.message)
          })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <div className="register-form login-modal">
            <form autoComplete="off" onSubmit={handleSubmit}>
              <Row>
                <Col lg={12} className="each-field">
                <FormattedMessage id="atlentis.register.form.email" defaultMessage="Email Address">
                  {placeholder =>
                  <input
                    type="email"
                    placeholder={placeholder}
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />}</FormattedMessage>
                  <ErrorValidation
                    touched={touched.email}
                    message={errors.email}
                  />
                </Col>
                <Col lg={12} className="each-field">
                <FormattedMessage id="atlentis.register.form.password" defaultMessage="Password">
                  {placeholder =>
                  <input
                    type="password"
                    placeholder={placeholder}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />}</FormattedMessage>
                  <ErrorValidation
                    touched={touched.password}
                    message={errors.password}
                  />
                </Col>
                <Col lg={12} className="login-modal-footer">
                  <div className="login-actions">
                    <button type="submit" className="primary-button">
                    <FormattedMessage id="button.login" defaultMessage="Login"></FormattedMessage>
                    </button>
                    <span onClick={() => {
                        setLoginModal(false)
                        setForgotPasswordModal(true);
                      }}><FormattedMessage id="atlentis.login.forgotpassword.text" defaultMessage="Forgot Password?"></FormattedMessage></span>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        )}
      </Formik>
    </DialogContent>
     </>
  );
}

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/scss/styles.scss";

import {IntlProvider} from 'react-intl';
import French from './Language/fr.json';
import German from './Language/de.json';
import English from './Language/en.json';
import Cookies from 'universal-cookie';
import GA4React from "ga-4-react";


const cookies = new Cookies();

const locale = cookies.get('language_locale') ? cookies.get('language_locale') : 'en';
const languageid = cookies.get('language_id') ? cookies.get('language_id') : 1;

let lang;
if (locale==="en") {
   lang = English;
} else {
   if (locale === "fr") {
       lang = French;
   } 
   else if (locale === "de") {
    lang = German;
  }
}
ReactDOM.render(
<IntlProvider defaultLocale={languageid} locale={locale} messages={lang} >
    <App />
</IntlProvider>, document.getElementById("root"));

try {
  setTimeout(_ => {
    const ga4react = new GA4React("G-FJJ134WD28");
    ga4react.initialize();
  }, 4000);
} catch (err) {}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

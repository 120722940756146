import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Cookies from "universal-cookie";

export default function Features() {
  const cookies = new Cookies();
  const selectedLanguageLocale = cookies.get("language_locale")
    ? cookies.get("language_locale").toUpperCase()
    : "EN";

  return (
    <div className="features">
      <div className="heading">
        <FormattedMessage
          id="atlentis.home_page.app_features.text"
          defaultMessage="ATLENTIS APP FEATURES"
        />
      </div>
      <div className="features__wrapper">
        <Container>
          <Row className="single-feature">
            <Col className="feature-image">
              <div>
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/features/F-1.jpg"
                  }
                  className="img-fluid"
                  alt=""
                />
              </div>
            </Col>
            <Col className="description-column">
              <div className="feature-description">
                <div className="title">
                  <FormattedMessage
                    id="atlentis.home_page.lidar_scanning.title"
                    defaultMessage="3D LIDAR Scanning Of Your Property"
                  />
                </div>
                <div className="description-container">
                  <div className="single-desc">
                    <FormattedMessage
                      id="atlentis.home_page.lidar_scanning.text1"
                      defaultMessage="No sketches! No laser measurements! No expensive scanners! No rotary devices or tripods!"
                    />
                  </div>
                  <div className="single-desc">
                    <FormattedMessage
                      id="atlentis.home_page.lidar_scanning.text2"
                      defaultMessage="Just walk through your property using our app to scan it (your smartphone must be equipped with a LIDAR scanner).All in just a few minutes."
                    />
                  </div>
                  <div className="single-desc">
                    <FormattedMessage
                      id="atlentis.home_page.lidar_scanning.text3"
                      defaultMessage="The scan will be processed on our servers and a 3D render of your property will be available within minutes. The scan and 3D render have captured spatial information, including your property measurements."
                    />
                  </div>
                  <div className="single-desc">
                    <FormattedMessage
                      id="atlentis.home_page.lidar_scanning.text4"
                      defaultMessage="Then order your to-scale floor plan."
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="features__wrapper">
        <Container>
          <Row className="single-feature">
            <Col className="feature-image">
              <div>
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/features/F-2.jpg"
                  }
                  className="img-fluid"
                  alt=""
                />
              </div>
            </Col>
            <Col className="description-column">
              <div className="feature-description">
                <div className="title">
                  <FormattedMessage
                    id="atlentis.home_page.create_floor_plans.title"
                    defaultMessage="Create Floor Plans"
                  />
                </div>
                <div className="description-container">
                  <div className="single-desc">
                    <FormattedMessage
                      id="atlentis.home_page.create_floor_plans.text1"
                      defaultMessage="One cannot control the external environment and your picture-perfect exterior photos are often left wanting due to a dull or cloudy day."
                    />
                  </div>
                  <div className="single-desc">
                    <FormattedMessage
                      id="atlentis.home_page.create_floor_plans.text2"
                      defaultMessage="Ready within 24 hours (or less – conditions apply)."
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="features__wrapper">
        <Container>
          <Row className="single-feature">
            <Col className="feature-image">
              <div>
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/features/F-3.png"
                  }
                  className="img-fluid"
                  alt=""
                />
              </div>
            </Col>
            <Col className="description-column">
              <div className="feature-description">
                <div className="title">
                  <FormattedMessage
                    id="atlentis.home_page.stunning_hdr_photos.title"
                    defaultMessage="Take Stunning HDR Photos"
                  />
                </div>
                <div className="description-container">
                  <div className="single-desc">
                    <FormattedMessage
                      id="atlentis.home_page.stunning_hdr_photos.text1"
                      defaultMessage="Take high quality HDR photos on your portable device. Capture the pictures and our servers-based AI engine will return stunning HDR pictures within minutes."
                    />
                  </div>
                  <div className="single-desc">
                    <FormattedMessage
                      id="atlentis.home_page.stunning_hdr_photos.text2"
                      defaultMessage="Available 1k, 2k or 4k pictures."
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="features__wrapper">
        <Container>
          <Row className="single-feature">
            <Col className="feature-image">
              <div>
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/features/F-4.jpg"
                  }
                  className="img-fluid"
                  alt=""
                />
              </div>
            </Col>
            <Col className="description-column">
              <div className="feature-description">
                <div className="title">
                  <FormattedMessage
                    id="atlentis.home_page.blue_sky_replacement.title"
                    defaultMessage="Blue Sky Replacement"
                  />
                </div>
                <div className="description-container">
                  <div className="single-desc">
                    <FormattedMessage
                      id="atlentis.home_page.blue_sky_replacement.text1"
                      defaultMessage="One cannot control the external environment and your picture-perfect exterior photos are often left wanting due to a dull or cloudy day."
                    />
                  </div>
                  <div className="single-desc">
                    <FormattedMessage
                      id="atlentis.home_page.blue_sky_replacement.text2"
                      defaultMessage="Help is at hand, identify your choice of replacement blue sky and tap to process."
                    />
                  </div>
                  {selectedLanguageLocale == "EN" && (
                    <div className="single-desc">
                      <FormattedMessage
                        id="atlentis.home_page.blue_sky_replacement.text3"
                        defaultMessage="Your image will be processed using our AI engine on our servers and returned to your device in minutes"
                      />
                    </div>
                  )}
                </div>
                {selectedLanguageLocale == "EN" && (
                  <div className="note">
                    <FormattedMessage
                      id="atlentis.home_page.blue_sky_replacement.text4"
                      defaultMessage="Available for HDR images only"
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="features__wrapper">
        <Container>
          <Row className="single-feature">
            <Col className="feature-image">
              <div>
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/features/F-5.jpg"
                  }
                  className="img-fluid"
                  alt=""
                />
              </div>
            </Col>
            <Col className="description-column">
              <div className="feature-description">
                <div className="title">
                  <FormattedMessage
                    id="atlentis.home_page.value_added_services.title"
                    defaultMessage="Value Added Services"
                  />
                </div>
                <div className="description-container">
                  <div className="single-desc">
                    <FormattedMessage
                      id="atlentis.home_page.value_added_services.text1"
                      defaultMessage="Object Removal, Virtual Home Staging or Renovation, etc..."
                    />
                  </div>
                  <div className="single-desc">
                    <FormattedMessage
                      id="atlentis.home_page.value_added_services.text2"
                      defaultMessage="How often have you taken pictures on a dull day or with unwanted objects; or needed to promote an empty property, or with dated décor? Order with our team of professionals directly from the app: object removal, virtual home staging or renovation, sky replacement, photo enhancement, etc."
                    />
                  </div>
                  {selectedLanguageLocale == "EN" && (
                    <div className="single-desc">
                      <FormattedMessage
                        id="atlentis.home_page.value_added_services.text3"
                        defaultMessage="Take advantage of all PLAN-IT-ALL Services!"
                      />
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="features__wrapper">
        <Container>
          <Row className="single-feature">
            <Col className="feature-image">
              <div>
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/features/F-6.jpg"
                  }
                  className="img-fluid"
                  alt=""
                />
              </div>
            </Col>
            <Col className="description-column">
              <div className="feature-description">
                <div className="title">
                  <FormattedMessage
                    id="atlentis.home_page.create_manage_project.title"
                    defaultMessage="Create & Manage Your Projects"
                  />
                </div>
                <div className="description-container">
                  <div className="single-desc">
                    <FormattedMessage
                      id="atlentis.home_page.create_manage_project.text1"
                      defaultMessage="Create a digital library for each project or for each property that is shared instantly among your various devices (on the move and in the office)."
                    />
                  </div>
                  <div className="single-desc">
                    <FormattedMessage
                      id="atlentis.home_page.create_manage_project.text2"
                      defaultMessage="All your HDR photos, photos, 3D renders, floor plans, and orders at your fingertips: whether on your smartphone, tablet or computer."
                    />
                  </div>
                  {selectedLanguageLocale == "EN" && (
                    <div className="single-desc">
                      <FormattedMessage
                        id="atlentis.home_page.create_manage_project.text3"
                        defaultMessage="All seamlessly shared and accessible."
                      />
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="features__wrapper">
        <Container>
          <Row className="single-feature">
            <Col className="feature-image">
              <div>
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/features/F-7.jpeg"
                  }
                  className="img-fluid"
                  alt=""
                />
              </div>
            </Col>
            <Col className="description-column">
              <div className="feature-description">
                <div className="title">
                  <FormattedMessage
                    id="atlentis.home_page.perfect_pictures.title"
                    defaultMessage="Perfect Pictures"
                  />
                </div>
                <div className="description-container">
                  <div className="single-desc">
                    <FormattedMessage
                      id="atlentis.home_page.perfect_pictures.text1"
                      defaultMessage="Create your own perfect pictures by Optimizing your photos using our editor tool to adjust brightness, contrast, saturation, clarity, shadows, exposure, sharpness & gamma levels."
                    />
                  </div>
                  <div className="single-desc">
                    <FormattedMessage
                      id="atlentis.home_page.perfect_pictures.text2"
                      defaultMessage="Use the transform feature to resize, rotate or crop your images."
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

import React from "react";
import { Container } from "react-bootstrap";
import {FormattedMessage, useIntl} from 'react-intl';
import { Link, useHistory } from "react-router-dom";

export default function Promotion({ selectedLanguage }) {
  return (
    <Container className="promotion">
      <div className="promotion__wrapper">
        <div className="promotion__image">
          <img
            src={
              process.env.PUBLIC_URL +
              "/assets/images/landing/atlentis-large.png"
            }
            className="img-fluid"
            alt=""
          />
          <div className="promotion__image--small">
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/images/landing/atlentis-small.png"
              }
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="description">
            <div className="title"><FormattedMessage id = "atlentis.home_page.free.text1" defaultMessage="TRY THE ATLENTIS APP FOR FREE" /></div>
            <div className="subtitle">
            <FormattedMessage id = "atlentis.home_page.free.text2" defaultMessage="Download the app and use your PLAN-IT-ALL.COM login to try out
              ATLENTIS features." />
            </div>
            <div className="text-center">
            <button className="primary-button">
            <Link to={`/${selectedLanguage.toLowerCase()}/register`}><FormattedMessage id = "button.get_started" defaultMessage="GET STARTED" /></Link>
            </button>
          </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Select, MenuItem } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorValidation from "../ErrorValidation";
import Breadcrumb from "../Resuable/Breadcrumb";
import SectionTitle from "../Resuable/SectionTitle";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as DropdownIcon } from "../../assets/icons/dropdown-icon-2.svg";
import {FormattedMessage, useIntl} from 'react-intl';
import Axios from "../../Config/Axios"
import cogoToast from 'cogo-toast';
import common  from "../../Language/common.json"
import Modal from "../Resuable/Modal";
import LoginModal from "./LoginModal";
import { EncryptValue } from "../../Encryption/Encryption";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Cookies from 'universal-cookie';
import Loader from "../Resuable/Loader";

const cookies = new Cookies();

export default function Register() {

  const intl = useIntl()

  const history = useHistory();
  const [loading, setLoading] = useState(false)

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);

  const [countryData, setCountryData] = useState([]);

  const [country, setCountry] = useState([]);
  const [language, setLanguage] = useState([]);

  useEffect(() => {

    if(cookies.get('access_token') !== undefined && cookies.get('access_token')!==""){
      history.push('/subscriptions')
    }
  }, []);

  const languageData = [
    { id: 1, name: <FormattedMessage id = "atlentis.register.english.language" defaultMessage="English" />, value: 1 },
    { id: 3, name: <FormattedMessage id = "atlentis.register.french.language" defaultMessage="French" />, value: 3 },
    { id: 2, name:  <FormattedMessage id = "atlentis.register.german.language" defaultMessage="German" />, value: 2 },
  ];

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(<FormattedMessage
                id = "atlentis.register.first_name.message"
                defaultMessage="First name is required "
            />)
      .test("length", <FormattedMessage
      id = "atlentis.register.first_name.length.message" defaultMessage="First Name must have more than 1 character" />, (value) => {
        return value && value.length > 1;
      })
      .test("alphabets", <FormattedMessage
      id = "atlentis.register.first_name.alphabetic.message" defaultMessage="Name must only contain alphabets" />, (value) => {
        return /^[A-Za-z\s]+$/.test(value);
      }),

    last_name: Yup.string()
      .required(<FormattedMessage
        id = "atlentis.register.last_name.message"
        defaultMessage="Last name is required "
    />)
      .test("length", <FormattedMessage
      id = "atlentis.register.last_name.length.message" defaultMessage="Last Name must have more than 1 character" />, (value) => {
        return value && value.length > 1;
      })
      .test("alphabets", <FormattedMessage
      id = "atlentis.register.last_name.alphabetic.message" defaultMessage="Last name must only contain alphabets" />, (value) => {
        return /^[A-Za-z\s]+$/.test(value);
      }),

   

    email: Yup.string().required(<FormattedMessage
      id = "atlentis.register.email.message" defaultMessage="Email is required" />).email(<FormattedMessage
        id = "atlentis.register.email.invalid" defaultMessage="Invalid Email ID" />),

    password: Yup.string().required(<FormattedMessage
      id = "atlentis.register.password.message" defaultMessage="Password is Required" />),

    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], <FormattedMessage
      id = "atlentis.register.password.notmatched" defaultMessage="Password don't match" />)
      .required(<FormattedMessage
        id = "atlentis.register.confirm_password.message" defaultMessage="Confirm Password is Required." />),

    country: Yup.string().required(<FormattedMessage
      id = "atlentis.register.country.message" defaultMessage="Country is Required" />),

    contact_number: Yup.string()
      .required(<FormattedMessage
        id = "atlentis.register.contact_number.message" defaultMessage="Phone Number is Required" />)
      .test("len", <FormattedMessage
      id = "atlentis.register.contact_number.length" defaultMessage="Must be exactly 10 digits " />, (value) => {
        return /^\d{10}$/.test(value);
      }),

    language: Yup.string().required(<FormattedMessage
      id = "atlentis.register.language.message" defaultMessage="Language is Required" />),

      
      

      
  });

  const MenuProps = {
    disableScrollLock: true,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  const handleFieldChange = (e, data, type) => {
    
    switch (type) {
      case "country":
        setCountry(e.target.value);
        data.country = e.target.value;
        break;
      case "language":
        setLanguage(e.target.value);
        data.language = e.target.value;
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    Axios.post('user/countrylist',{headers: {'accept': 'application/json'}}).then(response => {
      
      if(response.data.status)
      {
        
        setCountryData(response.data.data.items)
      }
      
  }).catch(function (error) {
      // handle error
  
  })
  }, []);

  return (
    <Fragment>
      {loading ? <Loader /> : "" }
    <div className="register page">
      <Container>
        <SectionTitle title={<FormattedMessage id="atlentis.register.page.title" defaultMessage="Create an Account"></FormattedMessage>} />
        <Breadcrumb>
          <ul className="breadcrumb__list">
            <li>
              <Link to={`/${intl.locale}`}><FormattedMessage id="atlentis.breadcrum.home" defaultMessage="Home"></FormattedMessage></Link>
            </li>
            <li><FormattedMessage id="atlentis.breadcrum.create_account" defaultMessage="Create an Account"></FormattedMessage></li>
          </ul>
        </Breadcrumb>
        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            company_name: "",
            email: "",
            password: "",
            confirm_password: "",
            country: "",
            contact_number: "",
            language: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            
            let patt = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
            let resultB = patt.test(values.password);
            if(resultB===false){
              cogoToast.error(common.[`${intl.locale}`]['atlentis.register.password.pattern'],5000)
              return 
            }
            if(acceptTerms===false){
              cogoToast.error(common.[`${intl.locale}`]['atlentis.register.terms.message'])
              
            }else{
              setLoading(true)
              Axios.post('/user/create',values,{headers: {'Lang': `${intl.defaultLocale}`}}).then(response => {
                
                if(response.data.status)
                {
                  localStorage.setItem('email',EncryptValue(values.email))
                  localStorage.setItem('password',EncryptValue(values.password))

                  history.push(`/${intl.locale}/otp-verification`)
                  setLoading(false)
                  //window.location.reload()
                }else
                {  
                  setLoading(false)
                  cogoToast.error(response.data.message)
                }
                
            }).catch(function (error) {
                // handle error
                setLoading(false)
                cogoToast.error(error.message)
            })
            }
            
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              className="register-form"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Row>
                <Col lg={6} md={6} sm={12} className="each-field">
                  
                  <FormattedMessage id="atlentis.register.form.first_name" defaultMessage="First Name">
                                                    {placeholder => <input 
                                            type="text"
                                            name="first_name"
                                            placeholder={placeholder}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.first_name}
                                            
                                        /> }
                                                </FormattedMessage>
                  <ErrorValidation
                    touched={touched.first_name}
                    message={errors.first_name}
                  />
                </Col>
                <Col lg={6} md={6} sm={12} className="each-field">
                <FormattedMessage id="atlentis.register.form.last_name" defaultMessage="Last Name">
                                                    {placeholder => 
                  <input
                    type="text"
                    name="last_name"
                    placeholder={placeholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.last_name}
                  />}</FormattedMessage>

                  <ErrorValidation
                    touched={touched.last_name}
                    message={errors.last_name}
                  />
                </Col>
                <Col lg={6} md={6} sm={12} className="each-field">
                <FormattedMessage id="atlentis.register.form.company" defaultMessage="Company Name">
                  {placeholder => 
                  <input
                    type="text"
                    name="company_name"
                    placeholder={placeholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.company_name}
                  />}</FormattedMessage>
                  <ErrorValidation
                    touched={touched.company_name}
                    message={errors.company_name}
                  />
                </Col>
                <Col lg={6} md={6} sm={12} className="each-field">
                <FormattedMessage id="atlentis.register.form.email" defaultMessage="Email Address">
                  {placeholder => <input
                    type="text"
                    name="email"
                    placeholder={placeholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />}</FormattedMessage>
                  <ErrorValidation
                    touched={touched.email}
                    message={errors.email}
                  />
                </Col>
                <Col lg={6} md={6} sm={12} className="each-field">
                <FormattedMessage id="atlentis.register.form.password" defaultMessage="Password">
                  {placeholder =>
                  <input
                    type="password"
                    name="password"
                    placeholder={placeholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />}</FormattedMessage>
                  <ErrorValidation
                    touched={touched.password}
                    message={errors.password}
                  />
                </Col>
                <Col lg={6} md={6} sm={12} className="each-field">
                <FormattedMessage id="atlentis.register.form.confirm_password" defaultMessage="Confirm Password">
                  {placeholder =>
                  <input
                    type="password"
                    name="confirm_password"
                    placeholder={placeholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirm_password}
                  />}</FormattedMessage>
                  <ErrorValidation
                    touched={touched.confirm_password}
                    message={errors.confirm_password}
                  />
                </Col>
                <Col lg={6} md={6} sm={12} className="each-field">
                  <Select
                    name="country"
                    value={country}
                    onBlur={handleBlur}
                    onChange={(e) => handleFieldChange(e, values, "country")}
                    displayEmpty
                    IconComponent={DropdownIcon}
                    MenuProps={MenuProps}
                    renderValue={
                      values.country !== ""
                        ? undefined
                        : () => (
                            <div className="select-placeholder">
                              <FormattedMessage id="atlentis.register.form.country" defaultMessage="Select Country"></FormattedMessage>
                            </div>
                          )
                    }
                  >
                    {countryData.map((single, i) => (
                      <MenuItem key={i} value={single.id}>
                        {single.title}
                      </MenuItem>
                    ))}
                  </Select>
                  <ErrorValidation
                    touched={touched.country}
                    message={errors.country}
                  />
                </Col>
                <Col lg={6} md={6} sm={12} className="each-field">
                <FormattedMessage id="atlentis.register.form.contact_number" defaultMessage="Phone Number">
                  {placeholder =>
                  <input
                    type="text"
                    name="contact_number"
                    placeholder={placeholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contact_number}
                  />}</FormattedMessage>
                  <ErrorValidation
                    touched={touched.contact_number}
                    message={errors.contact_number}
                  />
                </Col>
                <Col lg={6} md={6} sm={12} className="each-field">
                  <Select
                    name="language"
                    value={language}
                    onBlur={handleBlur}
                    onChange={(e) => handleFieldChange(e, values, "language")}
                    displayEmpty
                    IconComponent={DropdownIcon}
                    MenuProps={MenuProps}
                    renderValue={
                      values.language !== "" 
                        ? undefined
                        : () => (
                            <div className="select-placeholder">
                              <FormattedMessage id="atlentis.register.form.language" defaultMessage="Select Language"></FormattedMessage>
                            </div>
                          )
                    }
                  >
                    {languageData.map((single, i) => (
                      <MenuItem key={i} value={single.value}>
                        {single.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <ErrorValidation
                    touched={touched.language}
                    message={errors.language}
                  />
                </Col>
              </Row>
              <Col>
                <div className="register-terms">
                <div className="register-terms__checkbox">
                     <input
                       type="checkbox"
                       id="terms"
                       name="acceptTerms"
                       value={acceptTerms}
                       onChange={(e) => setAcceptTerms(e.target.checked)}
                     />
                      <label htmlFor="terms"></label>
                 </div>
                  <div className="register-terms__content">
                  <FormattedMessage id="terms.label1" defaultMessage="I agree with the Plan-It-All"/>&nbsp;
                    <a href={`${process.env.REACT_APP_WEBSITE_URL+intl.locale}/terms-conditions/`} target="_blank"><span style={{color:"#ff6f00"}}><FormattedMessage id="terms.label2" defaultMessage="Terms and Conditions"/></span> </a>  
                    <FormattedMessage id="terms.label3" defaultMessage="and" />&nbsp;
                    <a href={`${process.env.REACT_APP_WEBSITE_URL+intl.locale}/privacy-policy/`} target="_blank"><span style={{color:"#ff6f00"}}><FormattedMessage id="terms.label4" defaultMessage="Privacy Policy"/></span></a> 
                  </div>
                </div>
              </Col>
              <Col>
                <div className="register-footer">
                  <button className="primary-button"><FormattedMessage id="button.create_account" defaultMessage="Create my account"></FormattedMessage></button>
                  <span className="already-registered" onClick={() => setLoginModal(true)}>
                  <FormattedMessage id="atlentis.register.alreadyregister.text" defaultMessage="Already registered with PLAN-IT-ALL?"></FormattedMessage>
                  </span>
                </div>
              </Col>
            </form>
          )}
        </Formik>
      </Container>
    </div>
    <Modal
            maxWidth={"xs"}
            open={loginModal}
           handleClick={() => setLoginModal(false)}
           title={<FormattedMessage id="atlentis.login.page.title" defaultMessage="Login" />}
          >
            <LoginModal
              setLoginModal={setLoginModal}
              setForgotPasswordModal={setForgotPasswordModal}
            />
         </Modal>
    <Modal
      maxWidth={"xs"}
      open={forgotPasswordModal}
      handleClick={() => setForgotPasswordModal(false)}
      title={<FormattedMessage id="atlentis.forgotpassword.page.title" defaultMessage="Forgot Password" />}
      modalClass={"style2"}
    >
      <ForgotPassword
        setForgotPasswordModal={setForgotPasswordModal}
        setResetPasswordModal={setResetPasswordModal}
      />
    </Modal>
    <Modal
        maxWidth={"xs"}
        open={resetPasswordModal}
        handleClick={() => setResetPasswordModal(false)}
        title={<FormattedMessage id="atlentis.reset.page.title" defaultMessage="Reset Password" />}
        modalClass={"style2"}
      >
        <ResetPassword setResetPasswordModal={setResetPasswordModal} />
    </Modal>
     </Fragment>
  );
}
